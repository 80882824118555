import React, { Component, Suspense, lazy, createRef } from "react";
import { BrowserRouter, Route, Link, withRouter, Switch, } from "react-router-dom";
import { itemsWithHeading, options, FontsList, ThemesCells, catSubMenues, BreadCrumns, notificaitons } from "./Layout.model";
import AppRoutes from "../app/approuter";
import { getScreenMenuList, GetScreenListByUser } from "../_services/GenericService";
import AdqDialogBox from "./commonControls/AdqDialogBox/AdqDialogbox";
import { getStateList } from '../_services/GenericService';
import { getGenConfig, getOrgLogo, SaveGenConfig,posttaxillalogout } from '../_services/GenConfigService';
import { Menu, Breadcrumb, Select, Spin, Drawer, Radio, Avatar, Dropdown, message, Tooltip, Empty, Modal } from 'antd';
import { SettingOutlined, NotificationOutlined, MenuOutlined } from '@ant-design/icons';
import { CirclePicker, SketchPicker } from 'react-color';
import { logout } from '../configOidc';
import { getOrgDetails, getOrgprofile, GetLocationListByUserId, getTransactionDetailForInvoice } from '../_services/sale.service';
import jwt_decode from "jwt-decode";
import reactCSS from 'reactcss';
import AdqButton from './commonControls/AdqButton/AdqButton';
import { IoLogOutOutline, IoPersonOutline } from "react-icons/io5";
import { FiAlertTriangle, FiHelpCircle } from "react-icons/fi";
import { MdOutlineHelp } from "react-icons/md";
import PagenotFound from './PagenotFound';
import { connect } from "react-redux";
import { genConfigSaveObj } from "../_localDB/GeneralConfigNewDb";
import { FcManager } from "react-icons/fc";
import { HiMenuAlt2 } from "react-icons/hi";
import { getNotifyService } from "../_services/OmsWorkflowService";
import { getCountryList } from "../_services/GenericService";
import { saveBreadCrumb } from '../_redux/breadCrumb/action';
import { updateManageItem, locationApiCall, notificationApi } from '../_redux/loader/action';
import { generalConfigApi_request, getLedgTransList_request, getListofTerms_request, orgLogoApi_request } from "../_redux/OrgLogo/action";
import { orgBankApi_request } from "../_redux/OrgBank/action";
import DashBoardTab from "./Organization/Registration/DashBoardTab";
import { TfiLocationPin } from "react-icons/tfi";
import { handleConfigDataMapping } from "../_helper/generalConfigData";
// import HelpModal from "../customComponents/HelpModal";
import { getHelpContent } from "../_services/HelpServivce";
import { CreateCOAMasterDataForOrganisation } from "../_services/AccountService";
import { EnScoreSignUp } from "../_services/GenericService";

import Notification from "./Notification";
import { dashboardHelp, helpThing } from "../_localDB/helpTextDB";
import SubscriptionReq from "../customComponents/SubscriptionReq"
import BroadcastModel from "../customComponents/BroadcastModel";
import {isBrowser, isMobile } from 'react-device-detect';
const BroadcastMessage  = lazy(()=>import("../customComponents/BroadcastMessage"))
const HelpModal = lazy(()=>import("../customComponents/HelpModal"))
const { Option } = Select;

const routerLinks = AppRoutes;

const SampleTextBoxObj = {
  typectrl: "txt",
  ctrltype: "text",
  label: "sampleTextBox",
  name: "sampleTextBox",
  value: "",
  type: "text",
  placeholder: "",
  errormsg: false,
  isRequired: true,
  isSpecialChar: true,
  multiline: false,
  rows: 3,
  tooltip: '',
  isTooltip: false,
};

class LayoutPage extends Component {

  constructor(props) {
    super(props);
    this.fileUpload = React.createRef();
    this.refbutton  = React.createRef()
    this.linkRef = React.createRef()
    this.isDataSaved = sessionStorage.getItem('isDataSaved') ? sessionStorage.getItem('isDataSaved') : '';
    this.getOrgfromToken = this.getOrgfromToken(this);
    const cList = document.documentElement.classList;
    document.documentElement.removeAttribute("class");
    let selectedfont = localStorage.getItem("selectedfont");
    let selectedTheme = localStorage.getItem("selectedTheme")
      ? localStorage.getItem("selectedTheme")
      : "lightTheme";
    let themeColor = localStorage.getItem("themeColor");
    let currentMenu = sessionStorage.getItem("keypath")
      ? sessionStorage.getItem("keypath")
      : "";
    cList.add(selectedfont);
    cList.add(selectedTheme);
    cList.add(themeColor);
    this.state = {
      isOpen: sessionStorage.getItem('currentSelectMenu') ? false : true,
      collapseNavBar: false,
      subscriptionModal: false,
      subscriptionWarning: '',
      isSubscriptionExpIn14Days: false,
      stopLoadAllApis: false,
      openHelpModal: false,
      openSubMenuPanel:false,
      helpContent: helpThing,
      helpfilterData: dashboardHelp,
      dashboardHelpShow: false,
      helpButtonStatus: false,
      requestStatus: 0,
      itemUrl: '',
      screenKey: 0,
      isThemeOpen: false,
      navbarlist: [],
      navbarlistRawData: [],
      OrgLacation: [],
      OrgName: "",
      SubOrgLacation: [],
      orgImageLogoOBJ: "",
      orgImageDisplay: true,
      isOrgPanelOpen: false,
      displayColorPicker: false,
      locationMenu:false,
      sFont: localStorage.getItem("selectedfont") ? localStorage.getItem("selectedfont") : "Poppins",
      sTheme: selectedTheme ? selectedTheme : "",
      sColor: themeColor ? themeColor : "",
      currentPath: undefined,
      isConfigOpen: false,
      color: localStorage.getItem("themeUpdate") ? localStorage.getItem("themeUpdate") : '#104dba',
      error: false,
      dialogBoxData: true,
      dialogContent: {
        title: "This site can’t be reached",
        subText: "Reload the page?",
      },
      updateKey: "",
      selectedSubMenu: currentMenu,
      userDetailsRaw: JSON.parse(localStorage.getItem("userDetails")),
      selectedOrgLacation: localStorage.getItem("orgLoc")
        ? localStorage.getItem("orgLoc")
        : "",
      selectedLocData: localStorage.getItem("LocDATA")
        ? localStorage.getItem("LocDATA")
        : "",
      selectedSubOrgLacation: localStorage.getItem("subOrgLoc")
        ? localStorage.getItem("subOrgLoc")
        : "",
      orgLocCode: localStorage.getItem("LocCode")
        ? localStorage.getItem("LocCode")
        : "",
      UserName: localStorage.getItem("UserName")
        ? localStorage.getItem("UserName")
        : "",
      UserOrgCode: localStorage.getItem("OrgCode")
        ? localStorage.getItem("OrgCode")
        : "",
      accessPermission: '',
      findAccessMenu: [],
      orgSettingMenu: [],
      // notificaitonObj: notificaitons,
      notificaitonObj: [],
      orgTitleMarginTop : '0px',
      showApp: true,
      currentSelectMenu: sessionStorage.getItem('currentSelectMenu') ? JSON.parse(sessionStorage.getItem('currentSelectMenu')) : [],
    };


    this.settingmenu = (
      <Menu items={[
        this.state.OrgSettings && this.state.OrgSettings[0].submenu.map(item => {
          return (
            <>
              label: (
              <span key={item.key}>
                <Link to={item.url ? item.url : item.urls} onClick={() => this.navigationRouter(item)} > <i className={item.icon ? item.icon : ""}></i> {item.name}</Link>
              </span>
              )
            </>
          )
        }
        )

      ]}>
      </Menu>
    );
    this.usermenu = (
      <Menu style={{ minWidth: "150px" }} items={[
        {
          label: (
            <a rel="noopener noreferrer" disabled className="ClientInfo">
              <IoPersonOutline style={{ fontSize: 16, marginRight: "6px" }} />
              <span>{this.state.UserName ? this.state.UserName : ""}</span>
            </a>
          )
        },
        {
          label: (
            <a rel="noopener noreferrer" onClick={this.logoutUser}>
              <IoLogOutOutline style={{ fontSize: 16, marginRight: "6px" }} />
              Logout
            </a>
          )
        }]} >
      </Menu>
    );
  };

  logoutUser = (e) => {
    e.preventDefault()

    this.setState({ selectedSubMenu: ['1'] })
    this.refbutton.current.click()
    logout()
  }


  getBreadCrumb = (value, obj, key) => {
    // if(localStorage.length === 1){
    //   logout()
    // }
    if (obj !== null) {
      let filterData = BreadCrumns.filter(x => x.name === obj.name);
      if (filterData.length > 0) {
        sessionStorage.setItem("crtBreadCrumb", JSON.stringify(filterData[0].breadcrumbs));
        this.props.saveBreadCrumbAsync(null)
        this.setState({ currentPath: filterData[0].breadcrumbs });
      }
      else if (Array.isArray(obj)) {
        let reslutobj;
        const findInex = obj.findIndex(a => a.key === key)
        if (findInex !== -1) {
          let restArray = []
          for (let i = findInex + 1; i < obj.length; i++) {
            restArray.push(obj[i])
          }
          let count = 0;
          restArray.forEach(list => {
            if (list.path === obj[findInex].path || obj[findInex].path === null)
              count++
          })
          if (restArray.length !== count) {
            let newObj = [...obj]
            reslutobj = newObj.splice(0, findInex + 1)
          }

        }
        this.props.saveBreadCrumbAsync(null)
        this.setState({ currentPath: reslutobj ? reslutobj : obj });
      }
      else {
        let breadCrumb = {};
        if (value !== null) {
          let path = value.replaceAll("%20", " ");
          let activepath = path.substring(path.lastIndexOf("/") + 1);
          activepath = activepath.replace(/([A-Z])/g, ' $1').trim();
          if (activepath !== "dashboad" && activepath !== "Inventory Dashboard") {
            if (obj.length > 1) {
              let filterDetails = obj.findIndex(x => x.path === value);
              if (filterDetails !== -1) {
                breadCrumb = { text: activepath, key: activepath, url: path, name: obj[filterDetails].name }
                sessionStorage.setItem("crtBreadCrumb", null);
                this.props.saveBreadCrumbAsync(null);
                this.props.history.push(path);
              }
            } else {
              breadCrumb = { text: activepath, key: activepath, url: path, name: obj.name }
            }
          }
          this.setState({ currentPath: [breadCrumb] });
        }
        else {
          breadCrumb = undefined;
          this.setState({ currentPath: breadCrumb });
        }
      }
    }
    else {
      let crtBreadCrumb = sessionStorage.getItem("crtBreadCrumb");
      if (crtBreadCrumb === "null") {
        this.props.saveBreadCrumbAsync(null)
      } else {
        this.props.saveBreadCrumbAsync(JSON.parse(crtBreadCrumb))
        this.setState({ currentPath: JSON.parse(crtBreadCrumb) });
      }
    }
    this.removePrevOrg()
  }

  removePrevOrg = () => {
    let PrevOrgObj = JSON.parse(localStorage.getItem('PrevOrgObj'))
    if (PrevOrgObj !== undefined && PrevOrgObj) {
      localStorage.setItem("OrgCode", PrevOrgObj.prevOrg);
      localStorage.setItem("LocCode", PrevOrgObj.prevSubOrg);
      localStorage.removeItem('notifyLinkObj')
    }
  }

  getOrgfromToken = () => {
    if (localStorage.length === 1 || localStorage.length === 0) {
      logout()
    } else {
      if (sessionStorage.getItem("oidc.user:")) {
        let orgFromToken = "";
        let oidcUserObj = JSON.parse(sessionStorage.getItem("oidc.user:"));
        if (oidcUserObj) {
          let decoded = jwt_decode(oidcUserObj.id_token);
          orgFromToken = decoded.OrgId;
        }
        localStorage.setItem("OrgCode", orgFromToken);
        // here checking to call orglogo data
        this.props.OrgLogoApiCall();
        this.props.OrgBankApiCall();
        this.props.genConfigApiCall();
        this.props.termsCondListApiCall();
        // check & remove background cala from here  
        this.GetOrgDetails(orgFromToken);
      }
    };
  }
  showConfigPanel = () => {
    this.setState({ isConfigOpen: true });
  };

  showOrgPanel = () => {
    this.setState({ isOrgPanelOpen: true });
  };

  dismissConfigPanel = () => {
    this.setState({ isConfigOpen: false });
  };

  openThemePanel = () => {
    this.setState({ isThemeOpen: true });
  };

  dismissThemePanel = (e) => {
    e.preventDefault();
    this.setState({ isThemeOpen: false });
  };

  dismissOrgPanel = () => {
    this.setState({ isOrgPanelOpen: false });
  };

  openPanel = (e) => {
    e.preventDefault();
    this.state.currentSelectMenu.length > 0 && this.setState({ isOpen: !this.state.isOpen , openSubMenuPanel : false });
  };

  dismissPanel = (e) => {
    e.preventDefault();
    this.setState({ isOpen: false });
  };

  RedirectToTracet = (e) => {
    window.open("https://demo.tracet.in", "_blank");
  };

  getStateCodes = () => {
    let isStateDate = localStorage.getItem("stateWithCodes");
    if (isStateDate === null) {
      this.props.loader_reducer.loader = true;
      getStateList().then((response) => {
        if (response) {
          if (response.data.success === false) {
            this.props.loader_reducer.loader = false;
          } else {
            this.props.loader_reducer.loader = false;
            let data = response.data.details;
            let stateCodes = data.map((x) => x.code.toString());
            let obj = [];
            stateCodes.forEach((element) => {
              if (element.length === 1) {
                element = "0" + element;
              }
              obj.push(element);
            });
            let stateCodeString = JSON.stringify(obj);
            localStorage.setItem("stateCodes", stateCodeString);
            localStorage.setItem("stateWithCodes", JSON.stringify(data));
            this.setState({ showApp: false })
          }
        }
      });
    }
  };

  RedirectToListany = (e) => {
    window.open("https://admin.listany.com/eadmin/web/ssoLogin", "_blank");
  };

  RedirectToenTransat = (e) => {
    window.open("https://qa.entransact.com/oidc-login", "_blank");
  };

  showNavBar = () => {
    this.setState((prevState) => ({
      collapseNavBar: !prevState.collapseNavBar,
    }));
  };

  handleDropDownChange = (e, value) => {
    if (value.value) {
      const cList = document.documentElement.classList;
      if (cList.contains("SegoeUI")) {
        cList.remove("SegoeUI");
      } else if (cList.contains("Roboto")) {
        cList.remove("Roboto");
      } else if (cList.contains("OpenSans")) {
        cList.remove("OpenSans");
      } else if (cList.contains("Montserrat")) {
        cList.remove("Montserrat");
      } else if (cList.contains("Raleway")) {
        cList.remove("Raleway");
      } else if (cList.contains("Merriweather")) {
        cList.remove("Merriweather");
      } else if (cList.contains("Quicksand")) {
        cList.remove("Quicksand");
      }
      cList.add(value.value);
      localStorage.setItem("selectedfont", value.value);
      FontsList.key = value.value;
      this.setState({ sFont: value.value });
    }
  };

  //OrgLocationSerive
  GetOrgDetails = (orgFromToken) => {
    if (localStorage.length === 1 || localStorage.length === 0) {
      logout()
    } else {
      let obj = {};
      obj.orgCode = orgFromToken;
      if (this.isDataSaved === '') {
        this.props.loader_reducer.loader = true;
      }
      getOrgDetails(obj).then((response) => {
        if (response) {
          this.props.loader_reducer.loader = false;
          if (response.status === 200) {
            let data = response.data;
            let Optadd = "";
            if (data.addresses) {
              data.addresses.forEach((addr, idx) => {
                Optadd =
                  addr.addressLine1 ? addr.addressLine1 : '' +
                    "" +
                    addr.addressLine2 ? addr.addressLine2 : '' +
                      "" +
                      addr.addressLine3 ? addr.addressLine1 : '' +
                        " " +
                        addr.street ? addr.street : '' +
                          " " +
                          addr.city ? addr.city : '' +
                            "-" +
                            addr.state ? addr.state : '' +
                              " " +
                              addr.pinCode ? addr.pinCode : '' +
                                " " +
                                addr.country ? addr.country : '';
              });
            }
            let obj = {};
            let Option = [];
            obj.key = data.id;
            obj.text = data.name + ' | ' + data.code;
            obj.value = data.value;
            obj.code = data.code ? data.code : "";
            obj.gstin = data.gstin ? data.gstin : "";
            obj.addr = data.addresses;
            obj.address = Optadd;
            obj['registrationStatus'] = data.registrationStatus;
            Option.push(obj);
            localStorage.setItem("businessType", data.businessType)
            localStorage.removeItem("orgName");
            localStorage.removeItem("OrgLacation");

            let ORGOPt = Option[0].text ? Option[0].text : ''
            let ORGTEXT;
            if (ORGOPt !== '') {
              ORGTEXT = ORGOPt.split('|')
              Option[0].text = ORGTEXT[0]
            }
            localStorage.setItem(
              "orgName",
              Option[0].text ? JSON.stringify(Option[0]) : ""
            );
            this.setState({ isSubscriptionExpIn14Days: data.isSubscriptionExpireIn14Days, subscriptionWarning: data.subscriptionExpireWarning })
            if (Date.parse(data.subscriptionTo) < Date.parse(Date())) {
              this.setState({ subscriptionModal: true, subscriptionWarning: '', stopLoadAllApis: true })
            }
            else if (data.isSubscriptionExpireIn14Days && !localStorage.getItem('subscriptionWarning')) {
              this.setState({ subscriptionModal: true })
              localStorage.setItem('subscriptionWarning', true)
            }
            else {
              this.setState({ subscriptionModal: false })
            }
            this.setState(
              { OrgLacation: Option, OrgName: Option[0].text, requestStatus: data.requestStatus },
              () => {
                this.GetLocationListByUserId();
              }
            );
          }
          else {
            message.error("Check response status is not 200");
          }
        }
      });
    }
  };
  //end Here

  GetLocationListByUserId = (code) => {
    var obj = {
      UserName: this.state.UserName,
      OrgCode: this.state.UserOrgCode,
    };
    if (this.isDataSaved === '') {
      this.props.loader_reducer.loader = true;
    }
    GetLocationListByUserId(obj).then((response) => {
      if (response) {
        if (response.status === 200) {
          if (response.data.details.length > 0) {
            this.props.loader_reducer.loader = false;
            let data = response.data.details;
            let OrgLacationOptions = [];
            this.setState({ OrgLacation: [] });
            data.forEach((orgData) => {
              let OrgLacation = {};
              OrgLacation.DataObj = orgData;
              OrgLacation.key = orgData.id;
              OrgLacation.text = orgData.name + ' | ' + orgData.code
              OrgLacation.value = orgData.name;
              OrgLacation.code = orgData.code;
              OrgLacation.parentcode = code;
              let LocAddress = orgData.addresses[0] ? orgData.addresses[0] : null;
              if (LocAddress !== null) {
                OrgLacation.address =
                  LocAddress.addressLine2 ? LocAddress.addressLine2 : '' +
                    " " +
                    LocAddress.street ? LocAddress.street : '' +
                      " " +
                      LocAddress.city ? LocAddress.city : '' +
                        " " +
                        LocAddress.state ? LocAddress.state : '';
              } else {
                this.props.loader_reducer.loader = false;
              }
              OrgLacation.gstin = orgData.gstin;
              OrgLacationOptions.push(OrgLacation);
            });
            // "LocCode"
            let OrgCode = OrgLacationOptions[0].text.split('|')
            localStorage.setItem(
              "orgLoc",
              this.state.selectedOrgLacation !== ""
                ? this.state.selectedOrgLacation
                : OrgCode[0]
            );
            let defaultLocation = {
              children: OrgLacationOptions[0].value,
              key: OrgLacationOptions[0].key,
              value: OrgLacationOptions[0].value,
              obj: OrgLacationOptions[0],
              gstin: OrgLacationOptions[0].gstin
            };

            let locOptData = this.state.selectedLocData
            let setLocOpt = locOptData !== "" ? locOptData : JSON.stringify(defaultLocation)
            localStorage.setItem("LocDATA", setLocOpt);
            this.setState(
              {
                OrgLacation: OrgLacationOptions,
                selectedOrgLacation:
                  this.state.selectedOrgLacation !== ""
                    ? this.state.selectedOrgLacation
                    : OrgLacationOptions[0].text,
                selectedLocData: setLocOpt,
              },
              () => {
                let LOCDataObj = JSON.parse(localStorage.getItem("LocDATA"));
                this.onHandleBranchChange(
                  LOCDataObj.obj && LOCDataObj.obj.code ? LOCDataObj.obj.code
                    : OrgLacationOptions[0].code
                );
              }
            );
          }
        } else {
          this.props.loader_reducer.loader = false;
        }
      }
    });
  };

  GetListofCountry = () => {
    getCountryList().then((response) => {
      if (response) {
        if (response.status !== 200) {
        } else {
          let data = response.data.details;
          localStorage.setItem("countryWithCodes", JSON.stringify(data));
        }
      }
    }
    )
  }


  _onChange = (event, option) => {
    if (event.target.value) {
      const cList = document.documentElement.classList;
      if (cList.contains("defaultTheme")) {
        cList.remove("defaultTheme");
      } else if (cList.contains("lightTheme")) {
        cList.remove("lightTheme");
      } else if (cList.contains("darkTheme")) {
        cList.remove("darkTheme");
      }
      cList.add(event.target.value);
      localStorage.setItem("selectedTheme", event.target.value);
      this.setState({ sTheme: event.target.value });
    }
  };

  swatchColorPickerOnCellHovered = (id, color) => {
    if (id) {
      const cList = document.documentElement.classList;
      if (cList.contains("red")) {
        cList.remove("red");
      } else if (cList.contains("orange")) {
        cList.remove("orange");
      } else if (cList.contains("orangeYellow")) {
        cList.remove("orangeYellow");
      } else if (cList.contains("yellowGreen")) {
        cList.remove("yellowGreen");
      } else if (cList.contains("green")) {
        cList.remove("green");
      } else if (cList.contains("cyan")) {
        cList.remove("cyan");
      } else if (cList.contains("cyanBlue")) {
        cList.remove("cyanBlue");
      } else if (cList.contains("magenta")) {
        cList.remove("magenta");
      } else if (cList.contains("magentaPink")) {
        cList.remove("magentaPink");
      }
      localStorage.setItem("themeColor", id);
      cList.add(id);
      this.setState({ sColor: id });
    }
  };

  navigationRouter = (item, pos) => {

    this.setState({ screenKey: item.key })
    localStorage.removeItem("isB2C");
    localStorage.removeItem("transtype");
    localStorage.removeItem("screenname");
    sessionStorage.removeItem('branchCodeInStockLevel');
    sessionStorage.removeItem('accessPermission');
    sessionStorage.setItem("keypath", item.key);
    this.setState({ selectedSubMenu: item.key });
    sessionStorage.setItem('accessPermission', item.accessTypeId);
    let filterData = BreadCrumns.filter(x => x.name === item.name);
    if (filterData.length > 0) {
      sessionStorage.setItem("crtBreadCrumb", JSON.stringify(filterData[0].breadcrumbs));
      this.props.saveBreadCrumbAsync(null)
      this.setState({ currentPath: filterData[0].breadcrumbs });
    }
    sessionStorage.setItem('SCreenDataObj', JSON.stringify(item));
    if (item.url === "/layout/Manage Inventory") {
      sessionStorage.setItem('branchCodeInStockLevel', item.key);
      this.props.updateManageInventoryItem(item.key)
      this.props.history.push({ pathname: '/layout/Manage Inventory' });
      // this.setState({ showApp: false })
    }
    this.getPermAccess();
    this.getBreadCrumb(item.url, item);
    this.filterHelpData()

    if (pos === 'setting') {
      this.setState({ isOpen: true });
    }
  };
  filterHelpData = (val, list) => {
    let filterContent = this.state.helpContent;
    let filterdata = filterContent.filter((item) => parseInt(sessionStorage.getItem('keypath')) === item.screenId)
    if (!val && filterdata.length > 0 && filterdata[0].content) {
      this.setState({ helpfilterData: filterdata[0] })
      this.setState({ helpButton: true })
    }
    else {
      this.setState({ helpfilterData: [] })
      this.setState({ helpButton: false })
    }

    // else{
    //   this.setState({helpfilterData:this.state.helpfilterData})
    //   this.setState({helpButton:false})
    // }

  }
  handleSubModal = () => {
    this.setState({ subscriptionModal: false })
  }

  handleGetNotifyService = async () => {
    let Obj = {
      OrgCode: localStorage.getItem('OrgCode'),
      UserName: localStorage.getItem('UserName'),
    }

    await getNotifyService(Obj).then((response) => {
      if (response && response.status && response.status === 200) {
        let Data = response.data.details;
        this.setState({ notificaitonObj: Data.reverse() })
      }
    })
  }
  setListItemHighlight = () => {
    let filtervalue;
    this.state.navbarlistRawData.forEach(x => x.submenu.forEach(a => {
      if (a.url === window.location.pathname.replace(/%20/g, " ")) {
        filtervalue = a.key
      }
    }))
    return filtervalue
  }

  componentDidUpdate(prevProps, prevState) {
    if (localStorage.length === 1 || localStorage.length === 0) {
      logout()
    }
    if (this.state.currentPath) {
      this.clearBreadCrumbs()
    }
    if (this.state.currentPath !== prevState.currentPath) {
      this.setState({ currentPath: this.state.currentPath });
      this.clearBreadCrumbs()
      let keyValue = this.setListItemHighlight(this.state.navbarlistRawData)
      // if( keyValue){
      //   sessionStorage.setItem("keypath",keyValue);
      //   this.setState({ selectedSubMenu: keyValue });
      // }
    }
    if (this.state.showApp !== prevState.showApp && !this.state.stopLoadAllApis) {
      this.loadAll();
    }
    if (prevProps.orglogo_reducer.orgLogoData !== this.props.orglogo_reducer.orgLogoData) {
      this.handleOrgLogo()
    }
    // if (prevProps.orgBank_reducer.orgBankData !== this.props.orgBank_reducer.orgBankData) {
    //   // this.handleOrgLogo()
    //   message.error("llll")
    // }
    if (prevProps.loader_reducer.locationApiCall !== this.props.loader_reducer.locationApiCall) {
      this.props.updateLocatioApiCall(false)
      this.GetLocationListByUserId();
    }
    if (prevProps.loader_reducer.notificationApiCall !== this.props.loader_reducer.notificationApiCall) {
      this.handleGetNotifyService()
    }
    if (this.props.breadCrumb_reducer.breadCrumbList && prevProps.breadCrumb_reducer.breadCrumbList !== this.props.breadCrumb_reducer.breadCrumbList) {
      this.setState({ currentPath: this.props.breadCrumb_reducer.breadCrumbList });
    }
  }
  clearBreadCrumbs() {
    if (window.location.pathname === "/layout/Home") {
      this.filterHelpData(true, dashboardHelp)
      this.setState({ currentPath: undefined, currentSelectMenu: [], isOpen: true });
      this.props.saveBreadCrumbAsync(null)
    }
  }

  handleGetNotifyService = async () => {
    let Obj = {
      OrgCode: localStorage.getItem('OrgCode'),
      UserName: localStorage.getItem('UserName'),
    }

    await getNotifyService(Obj).then((response) => {
      if (response) {
        let Data = response.data.details

        this.setState({ notificaitonObj: Data.reverse() })

      }
    })
  }

  componentDidMount() {
    const url = window.location.pathname.replace(/\/\//g, "");
    window.history.pushState('page2', 'Title', url);
    !this.state.stopLoadAllApis && this.loadAll();
    this.COAGridData()
  }
  // to show the grid data in listing page
  COAGridData = async () => {
    let Obj = { OrgCode: localStorage.getItem('OrgCode') }
    await CreateCOAMasterDataForOrganisation(Obj).then(response => {
    })
    await EnScoreSignUp(Obj).then(response => {
    })
  }
  getHelpData = async () => {
    await getHelpContent().then(res => {
      if (res.status === 200) {
        let result = res.data.result.details
        this.setState({ helpContent: result })
      }
    })
  }

  handleOrgLogo = () => {
    let imagDispl = this.state.orgImageDisplay
    let imagByteData = this.props.orglogo_reducer.orgLogoData
    let dataURL = ""
    if (imagDispl && imagByteData && imagByteData !== "") {
      dataURL = `data:image/jpeg;base64,${imagByteData}`;
    }
    this.setState({ orgImageLogo: dataURL })
  }

  loadAll = () => {
    if (localStorage.length === 1 || localStorage.length === 0) {
      logout()
    } else {
      this.getHelpData()
      this.filterHelpData()
      this.getBreadCrumb(null, null);
      this.GetListofCountry();
      this.openThemePanel = this.openThemePanel.bind(this);
      this.dismissThemePanel = this.dismissThemePanel.bind(this);
      this.handleOrgLogo()
      this.openPanel = this.openPanel.bind(this);
      this.dismissPanel = this.dismissPanel.bind(this);
      this.dismissOrgPanel = this.dismissOrgPanel.bind(this);
      this.showNavBar = this.showNavBar.bind(this);
      this.swatchColorPickerOnCellHovered = this.swatchColorPickerOnCellHovered.bind(this);
      this.getStateCodes = this.getStateCodes.bind(this);
      let color = { hex: localStorage.getItem('themeUpdate') ? localStorage.getItem('themeUpdate') : "#104dba" };
      this.handleChangeComplete(color);
      let theme = localStorage.getItem('themeUpdate');
      this.props.changeThemeColor(theme);
      this.getPermAccess();
      if (this.isDataSaved === '') {
        this.props.loader_reducer.loader = true;
      }
      getScreenMenuList().then(
        (response) => {
          if (response.status !== 200) {
            //  
            this.props.loader_reducer.loader = false;
          } else {
            this.props.loader_reducer.loader = false;
            if (response.data.details) {
              var array=[];
              var newarray= [];
              response.data.details.forEach((parent,i) => {
                if(parent.name === "Organization"){
                  parent.submenu.forEach((chaild,j)    =>{
                      if(chaild.name === "Customer Master" || chaild.name === "Supplier Master"){
                        array.push(chaild)
                      }else{
                        newarray.push(chaild)
                      }
                  })
                parent.submenu = array.concat(newarray)
               
                }
                
                //console.log('totalArray',array.concate(newarray))
              }
            )
            console.log('parent',response.data.details)
              const result = response.data.details;
              result.forEach((parent, i) => {
                parent['id'] = parent.name.replace(/\s/g, '');
                if (parent.name !== 'Configuration') {
                  if (parent.submenu) {
                    parent.submenu.forEach((child) => {
                      child["displayId"] = 1;
                      child['id'] = child.name.replace(/\s/g, '');
                    });
                  }
                }
                else { parent.submenu = []; }
              });
              //  we removed after demo this code
              let CateloguesData = result.filter((x) => x.key === "70");
              if (CateloguesData.length > 0) {
                result.push(CateloguesData[0]);
              }

              //for temporary purposes add code begin here!
              result[0].icon = "far fa-file-alt";
              result[0].id = result[result.length - 1].id + 1;
              //for temporary purposes add code End here!
              this.setState({ navbarlistRawData: result, rootSubmenuKeys: result.map((x) => x.key) },
                () => {
                  this.accessBasedMenuFilter();
                }
              );
            }
          }
        },
        (error) => { }
      );
      this.handleGenConfig();
      let currentMenu = sessionStorage.getItem('currentSelectMenu') ? JSON.parse(sessionStorage.getItem('currentSelectMenu')) : [];
      if (currentMenu.length > 0) {
        this.currentMenuHandler(currentMenu[0], false)
      }
      if (window.location.pathname === "/layout" || window.location.pathname === "/layout/") {
        let obj1 = {
          accessTypeId: 5003, displayId: 1, icon: "fas fa-user", id: "Masters", key: "5",
          name: "Registration Dashboard", parentId: "33", url: "/layout/Home"
        }

        this.navigationRouter(obj1);
        this.props.history.push({ pathname: '/layout/Home' });
      }
      setTimeout(() => {
        this.setState({ showApp: true })
      }, 100)
      if (window.location.href.substring(window.location.href.lastIndexOf('/') + 1) === "Home") {
        this.setState({ currentPath: undefined, currentSelectMenu: [], isOpen: isMobile ? false: true });
        this.props.saveBreadCrumbAsync(null)
      }
    }
  }
  getPermAccess = () => {
    this.setState({ accessPermission: sessionStorage.getItem('accessPermission') });
  }

  handleGenConfig = () => {
    let Obj = {}
    Obj.OrgCode = localStorage.getItem('OrgCode');
    getGenConfig(Obj).then(async (response) => {
      if (response) {
        let result = response.data.result.details
        if (result === null) {
          let obj = genConfigSaveObj
          obj.trackInventory = true
          obj.isAllowNegative = false
          obj.createdBy = localStorage.getItem('UserName') || 'John Wick';
          obj.orgCode = localStorage.getItem('OrgCode');
          await SaveGenConfig(obj).then(response => {
            if (response.status === 200) {
              // response.data.result.success ? message.success(response.data.result.message) : message.error(response.data.result.message);
              this.setState({ showApp: false })
            }
          })
        }
        else {
          handleConfigDataMapping(result)
        }
      }
    })
  }

  accessBasedMenuFilter = () => {
    this.setState({ navbarlist: [] });
    let RawData = JSON.stringify(this.state.navbarlistRawData);
    let menuRawData = JSON.parse(RawData);
    let finalObj = [], parentObj = [], childRawObj = [], childObj = [], subChildObj = [];
    var obj = {
      UserName: localStorage.getItem('UserName'),
      OrgCode: localStorage.getItem('OrgCode'),
    };
    if (this.isDataSaved === '') {
      this.props.loader_reducer.loader = true;
    }
    GetScreenListByUser(obj).then((response) => {
      if (response.data.details.length > 0) {
        this.props.loader_reducer.loader = false;
        let data = response.data.details;
        if (data.length > 0) {
          // data.push({ id:80563, principalTypeId:1001, principalId:1, resourceTypeId:2, 
          //   resourceId:101, accessTypeId:5003, 
          //   orgId:0, isActive:true, createdDate:"2023-02-14T12:44:07.71", createdBy:null, 
          //   modifiedDate:null, modifiedBy:null, screenName:"Organisation Stock Levels", parentId:40
          // })

          data.forEach(parentData => {
            if (parentData.parentId !== 17) {
              var findIndex = menuRawData.findIndex(x => {
                return (parseInt(x.key) === parentData.parentId || parseInt(x.key) === parentData.resourceId)
              });
              if (findIndex !== -1) {
                if (parentData.accessTypeId !== 0) {
                  menuRawData[findIndex]['accessTypeId'] = parentData.accessTypeId;
                  if (this.state.navbarlistRawData[findIndex].submenu && parentData.parentId !== 0) {
                    this.state.navbarlistRawData[findIndex].submenu.forEach(e => {
                      e["parentId"] = parentData.parentId.toString();
                      childRawObj.push(e);
                    })
                  }
                  delete menuRawData[findIndex].submenu;
                  if (menuRawData[findIndex].key === "40") {
                    menuRawData[findIndex]["url"] = "/layout/InventoryDashboard";
                  }
                  parentObj.push(menuRawData[findIndex]);
                }
              }
            }
          });
          data.forEach(child => {
            if (child.parentId !== 17) {
              let findIndex = childRawObj.findIndex(x => parseInt(x.key) === child.resourceId);
              if (findIndex !== -1) {
                if (child.accessTypeId !== 0) {
                  if (childRawObj[findIndex].accessTypeId) {
                    if (childRawObj[findIndex].accessTypeId < child.accessTypeId) {
                      childRawObj[findIndex].accessTypeId = child.accessTypeId;
                    }
                  }
                  else {
                    childRawObj[findIndex]['accessTypeId'] = child.accessTypeId;
                  }
                  childObj.push(childRawObj[findIndex]);
                }
              }
            }
          });
          data.forEach(child => {
            if (child.parentId !== 17) {
              childRawObj.filter(eachVal => {
                if (eachVal.submenu.length > 0) {
                  let opt = eachVal.submenu.filter(nested => parseInt(nested.key) === child.resourceId);
                  if (opt.length > 0) {
                    opt[0]['accessTypeId'] = child.accessTypeId;
                    opt[0]['displayId'] = 1;
                    opt[0]['id'] = eachVal.name;
                    opt[0]['parentId'] = eachVal.key;
                    subChildObj.push(...opt);
                  }
                }
              });
            }
          });
          var parentObjUnique = parentObj.reduce((unique, o) => {
            if (!unique.some(obj => obj.key === o.key && obj.accessTypeId === o.accessTypeId)) { unique.push(o); } return unique;
          }, []);
          var childObjUnique = childObj.reduce((unique, o) => {
            if (!unique.some(obj => obj.key === o.key && obj.accessTypeId === o.accessTypeId)) { unique.push(o); } return unique;
          }, []);
          var subChildObjUnique = subChildObj.reduce((unique, o) => {
            if (!unique.some(obj => obj.key === o.key && obj.accessTypeId === o.accessTypeId)) { unique.push(o); } return unique;
          }, []);
          childObjUnique.forEach(mapping => {
            let filterData = subChildObjUnique.filter(x => x.parentId === mapping.key);
            if (filterData.length > 0) {
              return mapping['submenu'] = filterData;
            }
          });
          parentObjUnique.forEach(mapping => {
            let filterData = childObjUnique.filter(x => x.parentId === mapping.key);
            if (filterData.length > 0) {
              mapping['submenu'] = filterData;
              finalObj.push(mapping);
            } else {
              mapping['submenu'] = [];
              finalObj.push(mapping);
            }
          });
          let findAccessURLsList = [];
          findAccessURLsList.push(routerLinks[0]);
          findAccessURLsList.push(routerLinks[3]);
          findAccessURLsList.push(routerLinks[4]);
          findAccessURLsList.push(routerLinks[5]);
          findAccessURLsList.push({ path: "/404", exact: false, component: '' });
          let isAccessConfig = childObjUnique.findIndex(x => x.name === "Configuration");
          if (isAccessConfig !== -1) {
            findAccessURLsList.push(routerLinks[2]);
          }
          childObjUnique.forEach(menus => {
            let findAccessURLs = routerLinks.filter(x => x.path === menus.url);
            if (findAccessURLs.length > 0) {
              findAccessURLsList.push(findAccessURLs[0]);
            }
            let findRelatedAccessURL;
            if (menus.accessTypeId === 5000) {
              findRelatedAccessURL = routerLinks.filter(x => x.parent === menus.name && x.accessTypeId === 5000);
            } else if (menus.accessTypeId === 5001) {
              findRelatedAccessURL = routerLinks.filter(x => x.parent === menus.name && x.accessTypeId === 5001 ||
                x.parent === menus.name && x.accessTypeId === 5000);
            } else if (menus.accessTypeId === 5002) {
              findRelatedAccessURL = routerLinks.filter(x => x.parent === menus.name && x.accessTypeId === 5002 ||
                x.parent === menus.name && x.accessTypeId === 5000);
            } else if (menus.accessTypeId === 5003) {
              findRelatedAccessURL = routerLinks.filter(x => x.parent === menus.name);
            }
            if (findRelatedAccessURL !== undefined && findRelatedAccessURL.length > 0) {
              findRelatedAccessURL.forEach(e => {
                findAccessURLsList.push(e);
              })
            }
          })
          subChildObjUnique.forEach(menus => {
            let findAccessURLs = routerLinks.filter(x => x.path === menus.url);
            if (findAccessURLs.length > 0) {
              findAccessURLsList.push(findAccessURLs[0]);
            }
            let findRelatedAccessURL;
            if (menus.accessTypeId === 5000) {
              findRelatedAccessURL = routerLinks.filter(x => x.parent === menus.name && x.accessTypeId === 5000);
            } else if (menus.accessTypeId === 5001) {
              findRelatedAccessURL = routerLinks.filter(x => x.parent === menus.name && x.accessTypeId === 5001 ||
                x.parent === menus.name && x.accessTypeId === 5000);
            } else if (menus.accessTypeId === 5002) {
              findRelatedAccessURL = routerLinks.filter(x => x.parent === menus.name && x.accessTypeId === 5002 ||
                x.parent === menus.name && x.accessTypeId === 5000);
            } else if (menus.accessTypeId === 5003) {
              findRelatedAccessURL = routerLinks.filter(x => x.parent === menus.name);
            }
            if (findRelatedAccessURL !== undefined && findRelatedAccessURL.length > 0) {
              findRelatedAccessURL.forEach(e => {
                findAccessURLsList.push(e);
              })
            }
          })
          // let orgInd = finalObj.findIndex(x => x.name === 'Organization')
          // if (orgInd !== -1) {
          //   let orgObj = finalObj[orgInd]
          //   finalObj.splice(1, 1);
          //   finalObj.unshift(orgObj);
          // }

          let GSTIndex = finalObj.findIndex(x => x.key === '88')
          if (GSTIndex !== -1) {
            finalObj[GSTIndex].icon = "fas fa-file-invoice";
            finalObj[GSTIndex].submenu[0].icon = "fas fa-file-invoice";
          }
          // findAccessURLsList.push(routerLinks[routerLinks.length - 1]);
          findAccessURLsList.push(routerLinks[routerLinks.length - 2]);
          findAccessURLsList.push(routerLinks[routerLinks.length - 3]);

          let catalogueIndex = finalObj.findIndex(x => x.key === '59');
          if (catalogueIndex !== -1) {
            finalObj[catalogueIndex].submenu.push(...catSubMenues);
          }
          let filtercatSub = routerLinks.filter(x => x.parent === "CatManagement");
          if (filtercatSub.length > 0) {
            findAccessURLsList.push(...filtercatSub);
          }

          var array=[];
          var newarray= [];
          finalObj.forEach((parent,i) => {
            if(parent.name === "Organization"){
              parent.submenu.forEach((chaild,j)    =>{
                  if(chaild.name === "Customer Master" || chaild.name === "Supplier Master"){
                    array.push(chaild)
                  }else{
                    newarray.push(chaild)
                  }
              })
            parent.submenu = array.concat(newarray)
           
            }
          })
            //console.log('totalArray',array.concate(newarray))
          let OrgSettings = finalObj.filter(x => x.name === "Organization");
          let remainingMenu = finalObj.filter(x => x.name !== "Organization");

          // removing duplicates from the list...
          findAccessURLsList = [...new Map(findAccessURLsList.map(item => [item['path'], item])).values()]

          this.setState({ navbarlist: remainingMenu, orgSettingMenu: OrgSettings, findAccessMenu: findAccessURLsList }, () => {
            this.handleGetNotifyService()
            let lastURL = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
            if (lastURL !== '') {
              lastURL = lastURL.replace(/%20/g, " ");
              if (lastURL !== "layout") {
                let filterValue = findAccessURLsList.filter(x => x.path === `/layout/${lastURL}`);
                if (filterValue.length === 0 && lastURL !== 'Home') {
                  sessionStorage.setItem('accessPermission', undefined);
                  this.props.history.push({ pathname: '/404' });
                }
              }
            }
          });
        }
      } else {
        this.props.loader_reducer.loader = false;
      }
    });
    this.props.loader_reducer.loader = false;
  };

  onDialogboxHandle = (item, flag) => {
    this.setState({ dialogBoxData: flag });
    if (item === "No") {
    } else if (item === "Yes") {
    }
  };

  handleLink = (e, item) => {
    this.setState({ updateKey: item.key });
  };

  onOpenChange = (keys) => {
    const latestOpenKey = keys.find(
      (key) => this.state.selectedSubMenu.indexOf(key) === -1
    );
    if (this.state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ selectedSubMenu: keys });
    } else {
      this.setState({ selectedSubMenu: latestOpenKey ? [latestOpenKey] : [] });
    }
  };

  onSelectCheckBox = (value, obj, name, ctrl) => {
    var attObj = ctrl;
    var attIndex = attObj.findIndex((e) => e.name === name);
    if (attIndex !== -1) {
      attObj[attIndex].value = value;
      this.setState({ ctrl: attObj });
    }
  };

  handleButton = (value, labels) => {
    if (labels === "cancel") {
      this.props.history.push({
        pathname: "/layout/General Configuration",
        clearData: true,
      });
    } else if (labels === "save") {
      this.props.history.push({
        pathname: "/layout/salereturnnew",
        clearData: true,
      });
    }
  };

  handleChangeComplete = (color, event) => {
    this.setState({ color: color.hex });
    localStorage.setItem('themeUpdate', color.hex);
    this.props.changeThemeColor(color.hex);
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  onHandleOrgChange = (e, option, type) => {
    let OPTCode = option.value.split('|')
    option.value = OPTCode[0]
    if (type === 'Location') {
      this.onHandleBranchChange(option.obj.DataObj.addresses[0].code);
      this.setState({ selectedLocData: JSON.stringify(option) })
      localStorage.setItem("orgLoc", e);
      this.fileUpload.current.click()
      localStorage.setItem("LocDATA", JSON.stringify(option));
    }
    else {
       this.fileUpload.current.click()
      localStorage.setItem("subOrgLoc", option.value);
      localStorage.setItem("subOrgCode", option.obj.code);
      localStorage.setItem("LocCode", option.obj.code);
    }

    // localStorage.setItem("orgLoc", option.value);

    // localStorage.setItem("LocDATA", JSON.stringify(option));
    localStorage.setItem("brcDATA", option.obj.address);

    // localStorage.setItem("LocCode", );

  };

  currentMenuHandler = (item, type) => {
    let Array = [];
    this.setState({ currentSelectMenu: [] }, () => { })
    if (item.name === "Inventory") {
      let sub = item.submenu.filter(x => x.name !== "Inventory Adjustment" && x.name !== "Stock Transfer")
      if (sub.length > 0) {
        item.submenu = sub;
      }
      let newObject = {
        accessTypeId: 5003, displayId: 1, icon: null, id: "branchWiseStockLevels", key: "",
        name: "", parentId: "41", url: "/layout/Manage Inventory", submenu: []
      }

      item.submenu && item.submenu.forEach((x) => {
        if (x.key === '41') {
          let findIndex = x.submenu.findIndex(x => x.name === "Location Stock Levels");
          if (findIndex !== -1) {
            let newArray = [];
            let branchSubData = this.state.SubOrgLacation.length > 0 ? this.state.SubOrgLacation : localStorage.getItem("branchdata") ? JSON.parse(localStorage.getItem("branchdata")) : []
            branchSubData.forEach((element, i) => {
              let splitValues = element.addr[i].addressLine1.split(",")
              let newObj = JSON.parse(JSON.stringify(newObject));
              if (splitValues.length > 0) {
                //newObj.name = (splitValues[splitValues.length - 1] + ' ' + element.addr[i].city + ' ' + element.addr[i].state);
                newObj.name = (element.addr[i].name);
                // newObj.name = (element.addr[i].addressLine1 + ' ' + element.addr[i].city + ' ' + element.addr[i].state);

              }
              newObj.key = element.addr[i].code;
              newArray.push(newObj);
            })
            x.url = null
            //x.submenu[findIndex].url = null;

            // x.submenu[findIndex].urls = "null";  //"/layout/allopeningstock";
            x.submenu[findIndex].submenu = newArray;
          }
        }
      })

      // name : "Organisation Stock Levels"

    }
    if (item.name === "Organization") {
      item.url = null;
      item.urls = "/layout/Home";
    }
    Array.push(item);
    sessionStorage.setItem('currentSelectMenu', JSON.stringify(Array));
    this.setState({ currentSelectMenu: Array, isOpen: false },
      () => {
        sessionStorage.setItem("crtBreadCrumb", JSON.stringify(null));
        this.props.saveBreadCrumbAsync(null)
        if (type === true) {
          // if (Array[0].name === "Inventory") {
          //   // var xPathRes = document.evaluate('//*[@id="sidemenu"]/ul/li/a', document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
          //   // xPathRes.singleNodeValue.click();
          // }
          // else {
            var xPathRes = document.evaluate('//*[@id="sidemenu"]/ul/li/ul/li[1]/a', document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
            xPathRes.singleNodeValue.click();
          // }
        }
      });
  }



  onHandleBranchChange = (code) => {
    var obj = {};
    obj.orgCode = code;
    this.setState({ selectedSubOrgLacation: "" }, () => {
    });
    if (this.isDataSaved === '') {
      this.props.loader_reducer.loader = true;
    }
    getOrgprofile(obj).then((response) => {
      if (response) {
        if (response.status === 200) {
          this.props.loader_reducer.loader = false;
          let orgData = response.data.details;
          let shipaddr = [];
          let shipOption = [];
          if (orgData[0].addresses) {
            orgData[0].addresses.forEach((addr) => {
              let obj = {};
              let Optadd = "";
              if (addr.addressType === 2 || addr.addressType === 3) {
                let addressLine1 = addr.addressLine1 ? addr.addressLine1 : "";
                let addressLine2 = addr.addressLine2 ? addr.addressLine2 : "";
                let addressLine3 = addr.addressLine3 ? addr.addressLine3 : "";
                let street = addr.street ? addr.street : "";
                let city = addr.city ? addr.city : "";
                let state = addr.state ? addr.state : "";
                let country = addr.country ? addr.country : "";
                let pinCode = addr.pinCode ? addr.pinCode : "";
                Optadd = addressLine1 + " " + addressLine2 + " " + addressLine3 + " " + street + " " + city + " " + state + " " + pinCode + " " + country;
                obj.key = addr.id;
                obj.text = `${addr.name} | ${addr.addressLine1} | ${addr.city} | ${addr.state} - ${addr.pinCode}`; //orgData.name
                obj.code = addr.code ? addr.code : "";
                obj.gstin = orgData[0].gstin ? orgData[0].gstin : "";
                addr.registrationType = orgData[0].registrationStatus;
                shipaddr.push(addr);
                obj.addr = shipaddr;
                obj.partyCode = addr.code;
                obj.locationCode = addr.code;
                obj.address = Optadd;
                shipOption.push(obj);
              }
            });
          }
          localStorage.setItem("LocCode", shipOption[0].code);
          localStorage.setItem(
            "subOrgLoc",
            shipOption.length > 0 ? shipOption[0].text : ""
          );
          localStorage.setItem("subOrgCode", shipOption.length > 0 ? shipOption[0].code : "");
          localStorage.setItem("branchdata", JSON.stringify(shipOption));
          sessionStorage.setItem('isDataSaved', true);
          this.setState({
            SubOrgLacation: shipOption,
            selectedSubOrgLacation:
              shipOption.length > 0 ? shipOption[0].text : "",
            showApp: false
          }, () => {
            this.getStateCodes()
            // get all backgorund calls to here
            this.props.ledgTransListApiCall();
          });
        }
      }
    });
  };

  onTextfieldChangehandle = (e, value, name) => {
    SampleTextBoxObj.value = value;
  }

  onButtonhandler = async () => {
    let specialValue = SampleTextBoxObj.value.match(/[%<>+;,.|\\=-?:_~`[\]$'")/\-\{}+=(@!()&^#*]/)
    if (specialValue) {
      message.error("Script Tags or Special characters are not allowed");
      SampleTextBoxObj.value = '';
    }
    else {
      message.success("characters are allowed");
    }
  }

  handleNotifyLink = async (e, Obj) => {
    if (Obj.actionType === 1 && Obj.link) {
      let linkData = Obj.link.split(",")
      let OrdObj = {
        TransactionId: linkData[0].trim(),
        TransactionNumber: linkData[1].trim(),
        OrgCode: linkData[2].trim(),
        SubOrgCode: linkData[3].trim(),
      }
      Obj.TransactionId = linkData[0].trim()
      Obj.TransactionNumber = linkData[1].trim()
      Obj.OrgCode = linkData[2].trim()
      Obj.SubOrgCode = linkData[3].trim()
      Obj.orderStatus = linkData[4].trim()
      await this.handleOrderView(OrdObj, Obj)
    }
  }

  handleOrderView = async (OrdObj, notifyLinkObj) => {
    await getTransactionDetailForInvoice(OrdObj).then(async (response) => {
      if (response.status === 200) {
        let Result = response.data.result.details;
        if (Result) {
          localStorage.setItem('viewInvoice', JSON.stringify(Result));
          localStorage.setItem('notifyLinkObj', JSON.stringify(notifyLinkObj));
          var xPathRes = document.evaluate('//*[@id="goAPPName"]', document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
          xPathRes.singleNodeValue.click();

          setTimeout(() => {
            var xPathRes = document.evaluate('//*[@id="gonotification"]', document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
            xPathRes.singleNodeValue.click();
          }, 10)
        }
      }
    })
  }

  dismissNotification = (e, item, type) => {
    let listofNotifications = this.state.notificaitonObj;
    if (type === null) {
      let findIndex = listofNotifications.findIndex(x => x.id === item.id);
      if (findIndex !== -1) {
        listofNotifications.splice(findIndex, 1);
      }
    } else if (type === "All") {
      listofNotifications = [];
    }
    this.setState({ notificaitonObj: listofNotifications })
  }
  navigationRouet = (pageName) => {
    let obj = {
      OrganisationSettings: 'Configuration',
      PartyMaster: 'Party Master', ItemMaster: 'Item Master', Bills: 'Bills', Inventory: 'Manage Inventory', Invoices: 'Configuration', EInvoice: 'Invoice'
    }
    let listnav = this.state.navbarlist;
    let settingnav = this.state.orgSettingMenu[0].submenu
    let navic = settingnav.filter((item) => item.name === obj[pageName.replace(/\s/g, "")])
    if (navic.length > 0) {
      this.setState({ itemUrl: navic[0].url }, () => {
        this.linkRef.current.click()
        this.navigationRouter(navic[0], 'setting')
      })

    }
    else {
      listnav.forEach(item => {
        let screen = item.submenu.filter((subItem => subItem.name === obj[pageName.replace(/\s/g, "")]))
        if (screen.length > 0) {
          this.setState({ itemUrl: screen[0].url }, () => {
            this.linkRef.current.click()
            this.navigationRouter(screen[0])
          })
        }

      })

    }


    // console.log('fliterScreen',fliterScreen)
    // let aa = this.state.orgSettingMenu[0].submenu
    // let navic = aa.filter((item)=>item.name === "Locations")
    // this.setState({itemUrl:navic[0].url},()=>{ this.linkRef.current.click()
    //   this.navigationRouter(navic[0],'setting')})
    // setTimeout(()=>{
    //   this.linkRef.current.click()
    //   this.navigationRouter(navic[0],'setting')
    // },300)

    // this.props.history.push({
    //   pathname: navic[0].url,
    //   clearData: true,
    // });
  }

  // for checking notification
  static getDerivedStateFromProps(props, state) {
    if (props.dropdownConfig !== state.dropdownConfig) {
      return {
        configuration: props.dropdownConfig,
        data: props.dropdownData,
      };
    }
  }

  render() {
    const { sTheme, sFont, error, dialogBoxData, dialogContent, selectedOrgLacation,
      selectedSubOrgLacation, OrgName, notificaitonObj,
      orgImageLogo, orgImageDisplay } = this.state;
    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: this.state.color,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        }, popover: {
          position: 'absolute',
          zIndex: '2',
        }, cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
    if(isMobile){
      return (
        <BrowserRouter>
        <header className="ada-admin-header" style={{
          background: localStorage.getItem('selectedTheme') === "defaultTheme" ?
            localStorage.getItem('themeUpdate') : localStorage.getItem('selectedTheme') === "darkTheme" ? '#001529' : ''
        }}>
          <div style={{display:'none'}}>
          <form id="taxillaForm"  method="POST" action={`${process.env.REACT_APP_TAXILA_REDIRECT}/idm/logout`}>
          <button ref={this.refbutton} id='submit' type='submit'></button>
          </form>
          </div>
         
          <Suspense fallback={null}>
          <HelpModal isOpen={this.state.openHelpModal} dashboard={this.state.dashboardHelpShow} navigation={(val) => this.navigationRouet(val)} content={this.state.helpfilterData} changeModalStatus={() => this.setState({ openHelpModal: false })} />
          </Suspense>
          <BroadcastModel/>
          <span className="appTitle">
            <Link to="/layout/Home" id="goAPPName" ref={this.fileUpload}
              onClick={() => {
                this.setState({ currentPath: undefined, currentSelectMenu: [], isOpen: true , openSubMenuPanel : false }, () => {
                  // if(localStorage.length === 1){
                  //   logout()
                  // }
                });
                this.props.saveBreadCrumbAsync(null)
              }}>
              <span> <img src="/images/uBooks_360.jpg" alt="" className="ublogo" /></span>
            </Link>
          </span>
          <div style={{ display: 'none' }}>
            <Link to={this.state.itemUrl} ref={this.linkRef} />
          </div>
          <span style={{ marginRight: "auto", display: "flex",width:'100%', marginLeft: "12px", flexDirection:'column' }}> 
            {/* <Suspense fallback={null}><BroadcastMessage changeMargin={()=>this.setState({orgTitleMarginTop:'-25px'})} /></Suspense> */}
            <span style={{ marginRight: "auto", display: "flex", marginTop: this.state.orgTitleMarginTop }}>
           
            <Link to="/layout/Home" onClick={() => {
              this.setState({ currentPath: undefined, currentSelectMenu: [], isOpen: true });
              this.props.saveBreadCrumbAsync(null)
            }}>
              <span style={{ marginRight: "auto", display: "flex", }}>

                {orgImageLogo && orgImageLogo !== "" && orgImageDisplay &&
                  <img
                    width='50'
                    height='50'
                    id="imageOutputImg"
                    src={orgImageLogo}
                    style={{ width: '70px', height: '40px' }}
                    alt="Selected" >
                  </img>
                }
                <h4 className="OrgTitle" >
                  <Tooltip title={OrgName}>
                    {OrgName.length > 30 ? OrgName.substring(0, 30) + '...' : OrgName}
                  </Tooltip>
                </h4>
              </span>
            </Link>
            <p className="mt-2 settingsLevelIcon" style={{ cursor: "pointer", color: "#000" }}>
              <SettingOutlined />
              {this.state.orgSettingMenu.length > 0 ?
                <ul className="settingsLevelMenu">
                  {this.state.orgSettingMenu[0].submenu.map(item => {
                    return (
                      <li>
                        <Link to={item.url ? item.url : item.urls} onClick={() => {
                          this.navigationRouter(item, 'setting');
                          // this.setState({currentSelectMenu: []})
                        }}><i className="fas fa-window-minimize"></i> {item.name}</Link>
                        {item.name === "Masters" && item.submenu.length > 0 && (
                          <ul>
                            {item.submenu.map(sub => {
                              return (
                                <li>
                                  <Link to={sub.url ? sub.url : sub.urls} onClick={() => {
                                    this.navigationRouter(sub, 'setting');
                                    // this.setState({currentSelectMenu: []})
                                  }}><i className="fas fa-circle"></i> {sub.name}</Link>
                                </li>)
                            })}
                          </ul>
                        )}
                      </li>
                    )
                  })}
                </ul>
                : ''}
            </p>
            </span>

          </span>
          <div className="orgDrawerPannelSection">
            <i className="fas fa-sitemap pr-2 orgDrawerIcon" onClick={this.showOrgPanel}
            ></i>
            <Drawer
              className="productDrawer orgDrawer"
              placement="top"
              onClose={this.dismissOrgPanel}
              open={this.state.isOrgPanelOpen}
            >
              <div>
                {OrgName ? <h4 title={OrgName} className="OrgTitle">
                  {OrgName.length > 30 ? OrgName.substring(0, 30) + '...' : OrgName}
                </h4> : ""}
                <div className="currentInfor mt-3">
                  <label style={{fontWeight:"bold"}}>Tax Location:</label>
                  {selectedOrgLacation ? (
                    <Select id="selectedOrgLacation"
                      size="middle"
                      defaultValue={selectedOrgLacation}
                      style={{ width: "220px" }}
                      onChange={(e, value) =>
                        this.onHandleOrgChange(e, value, "Location")
                      }
                    >
                      {this.state.OrgLacation &&
                        this.state.OrgLacation.map((item) => {
                          return (
                            <Option key={item.key} value={item.text} obj={item}>
                              {item.text}
                            </Option>
                          );
                        })}
                    </Select>
                  ) : (
                    ""
                  )}
                </div>
                {selectedSubOrgLacation ? (
                  <div className="currentBranch mt-3">
                    <label style={{fontWeight:"bold"}}>Physical Location:</label>
                    <Select id="selectedSubOrgLacation"
                      size="middle"
                      defaultValue={selectedSubOrgLacation}
                      style={{ width: "220px" }}
                      onChange={(e, value) =>
                        this.onHandleOrgChange(e, value, "Branch")
                      }
                    >
                      {this.state.SubOrgLacation &&
                        this.state.SubOrgLacation.map((item) => {
                          return (
                            <Option key={item.key} value={item.text} obj={item}>
                              {item.text}
                            </Option>
                          );
                        })}
                    </Select>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Drawer>
          </div>

          <span className="currentInfor">
            <label style={{fontWeight:"bold"}}>Tax Location:</label>
            {selectedOrgLacation ? (
              <Select id="selectedOrgLacation"
                size="middle"
                defaultValue={selectedOrgLacation}
                style={{ width: "220px" }}
                onChange={(e, value) =>
                  this.onHandleOrgChange(e, value, "Location")
                }
              >
                {this.state.OrgLacation &&
                  this.state.OrgLacation.map((item) => {
                    return (
                      <Option key={item.key} value={item.text} obj={item}>
                        {item.text}
                      </Option>
                    );
                  })}
              </Select>
            ) : (
              ""
            )}
          </span>
          {selectedSubOrgLacation ? (
            <span className="currentBranch">
              <label style={{fontWeight:"bold"}}>Physical Location:</label>
              <Select id="selectedSubOrgLacation"
                size="middle"
                defaultValue={selectedSubOrgLacation}
                style={{ width: "220px" }}
                onChange={(e, value) =>
                  this.onHandleOrgChange(e, value, "Branch")
                }
              >
                {this.state.SubOrgLacation &&
                  this.state.SubOrgLacation.map((item) => {
                    return (
                      <Option key={item.key} value={item.text} obj={item}>
                        {item.text}
                      </Option>
                    );
                  })}
              </Select>
            </span>
          ) : (
            ""
          )}
          <SubscriptionReq isOpen={this.state.subscriptionModal} requestStatus={this.state.requestStatus} loader={(val) => this.props.loader_reducer.loader = val} subscriptionExpWarning={this.state.subscriptionWarning} modalChange={this.handleSubModal} />
          
          <div title={this.state.subscriptionWarning} style={{ marginTop: '15px', marginRight: '5px', marginLeft: '5px', display: this.state.isSubscriptionExpIn14Days ? '' : 'none' }}>
            <FiAlertTriangle color='red' size={30} onClick={() => this.setState({ subscriptionModal: true })} />
          </div>
          <div title='help' style={{ marginTop: '15px', marginRight: '10px', marginLeft: '10px', display: window.location.pathname === "/layout/Home" ? '' : 'none' }}>
            <FiHelpCircle onClick={() => { this.setState({ openHelpModal: true, dashboardHelpShow: true }); this.setState({ helpfilterData: dashboardHelp }) }} color="#f44336b3" size={30} style={{ cursor: 'pointer' }} />
          </div>
          <section className="avatarUserSection">
            <Dropdown overlay={this.usermenu} placement="bottom">
              <a
                href=""
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <Avatar size={40} style={{ lineHeight: "24px", background: "" }} icon={<FcManager style={{ fontSize: "39px", marginTop: "3px" }} />} />
              </a>
            </Dropdown>
          </section>
        </header>
        <div className="breadcrumSection">
          <button id="hamburgermeuId"
            className="ada-hamburgermeu"
            onClick={this.openPanel}
            type="button"
          >
         <MenuOutlined />
          </button>
          {
            window.location.pathname !== "/layout/Home" &&
            <button id="hamburgermeuId"
            className="ada-hamburgermeu"
            onClick={()=>this.setState({openSubMenuPanel:!this.state.openSubMenuPanel})}
            type="button"
          >
            <HiMenuAlt2 />
          </button>
          }
      
          <div className="px-1">
            <div className="breadcrumb-section" style={{ marginleft: this.state.collapseNavBar ? '' : "-190px" }}>
              {this.state.currentSelectMenu[0] ?
                <div style={{display:'none'}}>
                  <Breadcrumb>
                    {/* <Breadcrumb.Item key={itemsWithHeading.key}>
                      <Link to="" onClick={(e) => { this.setState({ selectedSubMenu: [] }) }}>
                        {this.state.currentSelectMenu[0] ? this.state.currentSelectMenu[0].name : ''}
                      </Link>
                    </Breadcrumb.Item> */}
                    {this.props.breadCrumb_reducer.breadCrumbList !== null ?
                      <>
                        {this.props.breadCrumb_reducer.breadCrumbList.map(item => {
                          return (<Breadcrumb.Item key={item.key}>
                            <Link to={item.url ? item.url : item.path} onClick={() => this.getBreadCrumb(item.path, this.props.breadCrumb_reducer.breadCrumbList, item.key)}>{item.name}</Link>
                          </Breadcrumb.Item>)
                        })}
                      </> : this.state.currentPath && this.state.currentPath !== undefined ?
                        <>
                          {this.state.currentPath.map(item => {
                            return (<Breadcrumb.Item key={item.key}>
                              <Link to={item.url ? item.url : item.path} onClick={() => this.getBreadCrumb(item.path, this.state.currentPath, item.key)}>{item.name}</Link>
                            </Breadcrumb.Item>)
                          })}
                        </> : ''
                    }
                  </Breadcrumb>
                </div>
                : this.state.currentPath && this.state.currentPath !== undefined ?
                  <div>
                    <Breadcrumb>
                      {this.state.currentPath.map(item => {
                        return (<Breadcrumb.Item key={item.key}>
                          <Link to={item.url ? item.url : item.path} onClick={() => this.getBreadCrumb(item.path, this.state.currentPath, item.key)}>{item.name}</Link>
                        </Breadcrumb.Item>)
                      })}
                    </Breadcrumb>
                  </div> : ''}
            </div>
          </div>
        </div>
        {this.state.showApp && (
          <section className="contentSecton">
            <div
              className={
                this.state.collapseNavBar
                  ? "mainNavSection collapseProduct"  
                  : "mainNavSection"
              } style={{width:'50%',  display:this.state.openSubMenuPanel?'':'none'}}
            >
              <div className="customSideMenu" id="sidemenu" >
                <ul className="mt-3">
                  {this.state.currentSelectMenu.length > 0 ? <>
                    {this.state.currentSelectMenu && this.state.currentSelectMenu.map((item) => {
                      if (item.submenu && item.submenu.length > 0) {
                        return (
                          <li key={item.key} className={(this.state.selectedSubMenu === item.key) ? 'active' : 'noActive'}>
                            <Link to={item.url ? item.url : item.urls} onClick={() => this.navigationRouter(item)} className="tetet"> <i className={item.icon ? item.icon : ""}></i> {item.name}</Link>
                            <ul>
                              {item.submenu && item.submenu.map((subItem) => {
                                if (subItem.displayId === 1) {
                                  if (subItem.submenu.length > 0) {
                                    // if (subItem.submenu.length > 0 && subItem.url === null) {
                                    return (
                                      <>
                                        <li
                                          className={(this.state.selectedSubMenu === subItem.key) ? 'active' : 'noActive'}
                                          key={subItem.key}
                                          id={subItem.id}
                                        >
                                          {subItem.url !== null ?
                                            <Link
                                              to={subItem.url ? subItem.url : subItem.urls}
                                              onClick={() => this.navigationRouter(subItem)} className=""
                                            >
                                              <i className="fas fa-window-minimize"></i>
                                              {subItem.name}
                                            </Link>
                                            : subItem.name === "Masters" ?
                                              <Link
                                                to={window.location.pathname}
                                                style={{ cursor: "not-allowed" }}
                                              >
                                                <i className="fas fa-window-minimize"></i>
                                                {subItem.name}
                                              </Link>
                                              : <Link
                                                className={(subItem.name === "Manage Inventory" ? 'curesorTest' : '')}
                                                to={subItem.url ? subItem.url : subItem.urls}
                                              >
                                                <i className="fas fa-window-minimize"></i>
                                                {subItem.name}
                                              </Link>
                                            // onClick={() => this.navigationRouter(subItem)}
                                          }
                                          <ul className="mt-2 orgloclink">
                                            {subItem.submenu && subItem.submenu.map((subItem1, i) => {
                                              return (
                                                <>
                                                  <li
                                                    className={`${(sessionStorage.getItem("keypath") === subItem1.key) ? 'active' : 'noActive'}`}
                                                    key={i}
                                                    id={subItem1.id + i}
                                                  >
                                                    {
                                                      subItem1.name === "Location Stock Levels" ?
                                                        <Link>
                                                          <i className="fas fa-window-minimize" ></i>
                                                          {subItem1.name}
                                                        </Link>
                                                        :
                                                        <Link
                                                          to={subItem1.url ? subItem1.url : subItem1.urls}
                                                          onClick={() => this.navigationRouter(subItem1)}
                                                        >
                                                          {/* <TfiLocationPin style={{marginRight:'5px'}}/>  */}
                                                          <i className="fas fa-window-minimize" ></i>
                                                          {subItem1.name}
                                                        </Link>
                                                    }
                                                  </li>
                                                  <>
                                                    {subItem1.submenu && subItem1.submenu.map((subItem2) => {
                                                      return (
                                                        <>
                                                          <li
                                                            key={i}
                                                            id={subItem2.id + i}
                                                            className={(sessionStorage.getItem("keypath") === subItem2.key) ? 'active p-all-SD-10 ' : 'noActive'}
                                                          >
                                                            <Link
                                                              title={subItem2.name}
                                                              to={subItem2.url ? subItem2.url : subItem2.urls}
                                                              onClick={() => this.navigationRouter(subItem2)}
                                                            >
                                                              <TfiLocationPin title={subItem2.name} style={{ marginRight: '5px' }} />
                                                              {subItem2.name.length > 20 ? subItem2.name.substring(0, 20) + '...' : subItem2.name}
                                                            </Link>
                                                          </li>
                                                        </>
                                                      )
                                                    })
                                                    }
                                                  </>
                                                  {/* </li> */}
                                                </>
                                              )
                                            })
                                            }
                                          </ul>
                                        </li>
                                      </>
                                    )
                                  } else {
                                    return (
                                      <li
                                        className={(this.state.selectedSubMenu === subItem.key) ? 'active' : 'noActive'}
                                        key={subItem.key}
                                      >
                                        <Link
                                          to={subItem.url ? subItem.url : subItem.urls}
                                          onClick={() => this.navigationRouter(subItem)}>
                                          <i className="fas fa-window-minimize"></i>
                                          {subItem.name}
                                        </Link>
                                      </li>
                                    )
                                  }
                                }
                              })}
                            </ul>
                          </li>
                        )
                      }
                    })
                    }
                  </> : ''}
                </ul>
              </div>
            </div>
            <div className="mainContent" style={{width:'100%'}}>
              <div className="col-md-12 pt-1 px-0">
                <div className="contentSection">
                  <div className="col-md-12">
                    <Route exact path='/layout' key="layout" >
                      <DashBoardTab layState={this.state !== undefined ? this.state : {}} />
                    </Route>
                    {this.state.findAccessMenu && this.state.findAccessMenu.map(route => {
                      return (<>
                        {(route.path && this.state.accessPermission === "5000")
                          || (route.path && this.state.accessPermission === "5001")
                          || (route.path && this.state.accessPermission === "5002")
                          || (route.path && this.state.accessPermission === "5003")
                          ? <Route path={route.path} component={route.component}  key={route.key} />
                          : <Route path="*" element={<PagenotFound />} />
                        }
                      </>)
                    })}
                  </div>
                </div>
              </div>
              <div style={{ position: 'fixed', right: '15px', bottom: '15px', display: this.state.helpButton && window.location.pathname !== "/layout/Home" ? '' : 'none' }}>
                <MdOutlineHelp color="#ef9d43" size={40} onClick={() => this.setState({ openHelpModal: true, dashboardHelpShow: false })} style={{ cursor: 'pointer' }} />
              </div>
            </div>
          </section>
        )}
        {this.props.loader_reducer.loader ? (
          <div className="spinLoader">
            <Spin size="large" />
          </div>
        ) : (
          ""
        )}
        <div >
          <Drawer
            id="manageMenu"
            className="productMobileDrawer"
            placement="top"
            closable={false}
            onClose={this.dismissPanel}
            open={this.state.isOpen}
            style={{ width: "30%" }}
          >
            <div className="ourProducts" style={{display:'grid',gridTemplateColumns:'auto',width: "40%"}}>
              {this.state.navbarlist.map(item =>
                <div
                  className={this.state.currentSelectMenu.length > 0 && this.state.currentSelectMenu[0].name === item.name ? 'active' : 'noActive'}
                  style={{width:'100%'}}
                  onClick={() => this.currentMenuHandler(item, true)}
                >
                  <i className={item.icon ? item.icon : ""}></i>
                  {item.name}
                </div>
              )}
            </div>
          </Drawer>
        </div>
        <Drawer
          title="Themes"
          placement="right"
          onClose={this.dismissThemePanel}
          open={this.state.isThemeOpen}
        >
          <Radio.Group onChange={this._onChange} id="isThemeOpen" value={sTheme}>
            {options.map((item) => {
              return (
                <Radio key={item.key} value={item.text} disabled>
                  {item.displayValue}
                </Radio>
              );
            })}
          </Radio.Group>
          <label htmlFor="selectFont" className="chooseColor">
            Selected Font
          </label>
          <Select id="selectFont"
            style={{ width: "100%" }}
            defaultValue={sFont}
            value={FontsList.key} disabled
            onChange={(event, value) => this.handleDropDownChange(event, value)}
          >
            {FontsList.Option.map((item) => {
              return (
                <Option key={item.key} value={item.text}>
                  {item.text}
                </Option>
              );
            })}
          </Select>
          <div className="SwatchColorPickerSection">
            <label htmlFor="chooseColor" className="chooseColor">
              Choose Color
            </label>
            <CirclePicker colors={ThemesCells} circleSize={30} onChange={this.handleChangeComplete} disabled={true} />
            <div style={styles.swatch} onClick={this.handleClick}>
              <div style={styles.color} />
            </div>
            {this.state.displayColorPicker ? <div style={styles.popover}>
              <div style={styles.cover} onClick={this.handleClose} />
              <SketchPicker color={this.state.color} onChange={this.handleChangeComplete} disabled />
            </div> : null}
          </div>
        </Drawer>
        {error ? (
          <AdqDialogBox
            dialogBox={dialogBoxData}
            dialogContentProps={dialogContent}
            selectedDialogBoxValue={this.onDialogboxHandle}
          />
        ) : ("")}
      </BrowserRouter>
      )
    }

    return (
      <BrowserRouter>
        <header className="ada-admin-header" style={{
          background: localStorage.getItem('selectedTheme') === "defaultTheme" ?
            localStorage.getItem('themeUpdate') : localStorage.getItem('selectedTheme') === "darkTheme" ? '#001529' : ''
        }}>
           <div style={{display:'none'}}>
          <form id="taxillaForm"  method="POST" action={`${process.env.REACT_APP_TAXILA_REDIRECT}/idm/logout`}>
          <button ref={this.refbutton} id='submit' type='submit'></button>
          </form>
          </div>
          <Suspense fallback={null}>
          <HelpModal isOpen={this.state.openHelpModal} dashboard={this.state.dashboardHelpShow} navigation={(val) => this.navigationRouet(val)} content={this.state.helpfilterData} changeModalStatus={() => this.setState({ openHelpModal: false })} />
          </Suspense>
          <BroadcastModel/>
          <span className="appTitle">
            <Link to="/layout/Home" id="goAPPName" ref={this.fileUpload}
              onClick={() => {
                this.setState({ currentPath: undefined, currentSelectMenu: [], isOpen: true, openSubMenuPanel : false }, () => {
                  // if(localStorage.length === 1){
                  //   logout()
                  // }
                });
                this.props.saveBreadCrumbAsync(null)
              }}>
              <span> <img src="/images/uBooks_360.jpg" alt="" className="ublogo" /></span>
            </Link>
          </span>
          <div style={{ display: 'none' }}>
            <Link to={this.state.itemUrl} ref={this.linkRef} />
          </div>
          <span style={{ marginRight: "auto", display: "flex",width:'100%', marginLeft: "12px", flexDirection:'column' }}> 
            {/* <Suspense fallback={null}><BroadcastMessage changeMargin={()=>this.setState({orgTitleMarginTop:'-25px'})} /></Suspense> */}
            <span style={{ marginRight: "auto", display: "flex", marginTop: this.state.orgTitleMarginTop }}>
           
            <Link to="/layout/Home" onClick={() => {
              this.setState({ currentPath: undefined, currentSelectMenu: [], isOpen: true },()=>{
                localStorage.removeItem("isB2C");
                sessionStorage.removeItem('branchCodeInStockLevel');
              });
              this.props.saveBreadCrumbAsync(null)
            }}>
              <span style={{ marginRight: "auto", display: "flex", }}>

                {orgImageLogo && orgImageLogo !== "" && orgImageDisplay &&
                  <img
                    width='50'
                    height='50'
                    id="imageOutputImg"
                    src={orgImageLogo}
                    style={{ width: '70px', height: '40px' }}
                    alt="Selected" >
                  </img>
                }
                <h4 className="OrgTitle" >
                  <Tooltip title={OrgName}>
                    {OrgName.length > 30 ? OrgName.substring(0, 30) + '...' : OrgName}
                  </Tooltip>
                </h4>
              </span>
            </Link>
            <p className="mt-2 settingsLevelIcon" style={{ cursor: "pointer", color: "#000" }}>
              <SettingOutlined />
              {this.state.orgSettingMenu.length > 0 ?
                <ul className="settingsLevelMenu">
                  {this.state.orgSettingMenu[0].submenu.map(item => {
                    return (
                      <li>
                        <Link to={item.url ? item.url : item.urls} onClick={() => {
                          this.navigationRouter(item, 'setting');
                          // this.setState({currentSelectMenu: []})
                        }}><i className="fas fa-window-minimize"></i> {item.name}</Link>
                        {item.name === "Masters" && item.submenu.length > 0 && (
                          <ul>
                            {item.submenu.map(sub => {
                              return (
                                <li>
                                  <Link to={sub.url ? sub.url : sub.urls} onClick={() => {
                                    this.navigationRouter(sub, 'setting');
                                    // this.setState({currentSelectMenu: []})
                                  }}><i className="fas fa-circle"></i> {sub.name}</Link>
                                </li>)
                            })}
                          </ul>
                        )}
                      </li>
                    )
                  })}
                </ul>
                : ''}
            </p>
            </span>

          </span>
          <div className="orgDrawerPannelSection">
            <i className="fas fa-sitemap pr-2 orgDrawerIcon" onClick={this.showOrgPanel}
            ></i>
            <Drawer
              className="productDrawer orgDrawer"
              placement="top"
              onClose={this.dismissOrgPanel}
              open={this.state.isOrgPanelOpen}
            >
              <div>
                {OrgName ? <h4 title={OrgName} className="OrgTitle">
                  {OrgName.length > 30 ? OrgName.substring(0, 30) + '...' : OrgName}
                </h4> : ""}
                <div className="currentInfor mt-3">
                  <label style={{fontWeight:"bold"}}>Tax Location:</label>
                  {selectedOrgLacation ? (
                    <Select id="selectedOrgLacation"
                      size="middle"
                      defaultValue={selectedOrgLacation}
                      style={{ width: "220px" }}
                      onChange={(e, value) =>
                        this.onHandleOrgChange(e, value, "Location")
                      }
                    >
                      {this.state.OrgLacation &&
                        this.state.OrgLacation.map((item) => {
                          return (
                            <Option key={item.key} value={item.text} obj={item}>
                              {item.text}
                            </Option>
                          );
                        })}
                    </Select>
                  ) : (
                    ""
                  )}
                </div>
                {selectedSubOrgLacation ? (
                  <div className="currentBranch mt-3">
                    <label style={{fontWeight:"bold"}}>Physical Location:</label>
                    <Select id="selectedSubOrgLacation"
                      size="middle"
                      defaultValue={selectedSubOrgLacation}
                      style={{ width: "220px" }}
                      onChange={(e, value) =>
                        this.onHandleOrgChange(e, value, "Branch")
                      }
                    >
                      {this.state.SubOrgLacation &&
                        this.state.SubOrgLacation.map((item) => {
                          return (
                            <Option key={item.key} value={item.text} obj={item}>
                              {item.text}
                            </Option>
                          );
                        })}
                    </Select>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Drawer>
          </div>

          <span className="currentInfor">
            <label style={{fontWeight:"bold"}}>Tax Location:</label>
            {selectedOrgLacation ? (
              <Select id="selectedOrgLacation"
                size="middle"
                defaultValue={selectedOrgLacation}
                style={{ width: "220px" }}
                onChange={(e, value) =>
                  this.onHandleOrgChange(e, value, "Location")
                }
              >
                {this.state.OrgLacation &&
                  this.state.OrgLacation.map((item) => {
                    return (
                      <Option key={item.key} value={item.text} obj={item}>
                        {item.text}
                      </Option>
                    );
                  })}
              </Select>
            ) : (
              ""
            )}
          </span>
          {selectedSubOrgLacation ? (
            <span className="currentBranch">
              <label style={{fontWeight:"bold"}}>Physical Location:</label>
              <Select id="selectedSubOrgLacation"
                size="middle"
                defaultValue={selectedSubOrgLacation}
                style={{ width: "220px" }}
                onChange={(e, value) =>
                  this.onHandleOrgChange(e, value, "Branch")
                }
              >
                {this.state.SubOrgLacation &&
                  this.state.SubOrgLacation.map((item) => {
                    return (
                      <Option key={item.key} value={item.text} obj={item}>
                        {item.text}
                      </Option>
                    );
                  })}
              </Select>
            </span>
          ) : (
            ""
          )}
          <SubscriptionReq isOpen={this.state.subscriptionModal} requestStatus={this.state.requestStatus} loader={(val) => this.props.loader_reducer.loader = val} subscriptionExpWarning={this.state.subscriptionWarning} modalChange={this.handleSubModal} />
          {/* Notification commented untill MVP1.0 Relese */}
          <div className= { notificaitonObj && notificaitonObj.length > 0 ? "mr-3 notificationIconLink" : "mr-3 noNotificationIconLink" }  style={{ cursor: "pointer" }}>
            <a href="" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              <NotificationOutlined style={{ fontSize: '35px' }} />

              <div className="notificationBox">
                <h6>Notifications</h6>
                {notificaitonObj && notificaitonObj.length > 0 ?
                  <>
                    <Link className="d-none" to="/layout/SaleOrderView" id="gonotification">#</Link>
                    <ul>
                      {notificaitonObj.map((items, id) => {
                        return (
                          <li key={id}>
                            <Tooltip title={items.message}>
                              <span><i className="fas fa-exclamation-circle" style={{ color: this.state.color, fontSize: "12px", marginRight: "5px" }}></i></span>
                            </Tooltip>
                            <p>{items.message}</p>
                            {items.link ?
                              <>
                                <a className="verifylinkPosition mr-2 aw38"
                                  style={{ float: "right" }} href="" onClick={(e) => { this.handleNotifyLink(e, items) }} >Link</a>
                              </> : ''}
                            <span className="noficitaionDismiss" onClick={(e) => { this.dismissNotification(e, items, null) }}>&#10005;</span>
                          </li>
                        )
                      })
                      }
                    </ul>
                    <AdqButton buttonData={notificationBtnObj} buttonAction={(e) => { this.dismissNotification(e, null, "All") }} />
                  </>
                  :
                  <div style={{ margin: "80px 0" }}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        <span className="text-center">
                          <b>Hurray !</b><br />
                          you are all caught up !
                        </span>
                      }
                    />
                  </div>
                }
              </div>

              {/* Backup Code written in separate component */}
          {/* {
                notificaitonObj && notificaitonObj.length > 0 && (
                  <Notification stateObj = {this.state} history = {history} />
                )
              } */}

          </a>
          </div> 
          <div title={this.state.subscriptionWarning} style={{ marginTop: '15px', marginRight: '5px', marginLeft: '5px', display: this.state.isSubscriptionExpIn14Days ? '' : 'none' }}>
            <FiAlertTriangle color='red' size={30} onClick={() => this.setState({ subscriptionModal: true })} />
          </div>
          <div title='help' style={{ marginTop: '15px', marginRight: '10px', marginLeft: '10px', display: window.location.pathname === "/layout/Home" ? '' : 'none' }}>
            {/* <a href= '/files/uBooksHelp.docx' download="userGuide" >
          <FiHelpCircle color="#f44336b3" size={30}/></a> */}
            <FiHelpCircle onClick={() => { this.setState({ openHelpModal: true, dashboardHelpShow: true }); this.setState({ helpfilterData: dashboardHelp }) }} color="#f44336b3" size={30} style={{ cursor: 'pointer' }} />
          </div>
          <section className="avatarUserSection">
            <Dropdown overlay={this.usermenu} placement="bottom">
              <a
                href=""
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <Avatar size={40} style={{ lineHeight: "24px", background: "" }} icon={<FcManager style={{ fontSize: "39px", marginTop: "3px" }} />} />
              </a>
            </Dropdown>
          </section>
        </header>
        <div className="breadcrumSection">
          <button id="hamburgermeuId"
            className="ada-hamburgermeu"
            onClick={this.openPanel}
            type="button"
          >
            <HiMenuAlt2 />
          </button>
          <div className="px-1">
            <div className="breadcrumb-section" style={{ marginleft: this.state.collapseNavBar ? '' : "-190px" }}>
              {this.state.currentSelectMenu[0] ?
                <div>
                  <Breadcrumb>
                    {/* <Breadcrumb.Item key={itemsWithHeading.key}>
                      <Link to="" onClick={(e) => { this.setState({ selectedSubMenu: [] }) }}>
                        {this.state.currentSelectMenu[0] ? this.state.currentSelectMenu[0].name : ''}
                      </Link>
                    </Breadcrumb.Item> */}
                    {/* <label>{this.state.currentSelectMenu[0].name}</label> */}
                    {this.props.breadCrumb_reducer.breadCrumbList !== null ?
                      <>
                           {this.state.currentSelectMenu[0].submenu.some(obj=>obj.key === this.state.screenKey)&& <Link style={{color:'#5673e5'}}><b style={{fontSize:'15px'}}>{`${this.state.currentSelectMenu[0].name} > `}</b></Link>}
                        {this.props.breadCrumb_reducer.breadCrumbList.map(item => {
                          return (<Breadcrumb.Item key={item.key}>
                            <Link to={item.url ? item.url : item.path} onClick={() => this.getBreadCrumb(item.path, this.props.breadCrumb_reducer.breadCrumbList, item.key)}>{item.name}</Link>
                          </Breadcrumb.Item>)
                        })}
                      </> : this.state.currentPath && this.state.currentPath !== undefined ?
                        <>
                          {this.state.currentSelectMenu[0].submenu.some(obj=>obj.key === this.state.screenKey)&& <Link  style={{color:'#5673e5'}}><b style={{fontSize:'15px'}}>{`${this.state.currentSelectMenu[0].name} > `}</b></Link>}
                          {this.state.currentPath.map(item => {
                            return (<Breadcrumb.Item key={item.key}>
                              <Link to={item.url ? item.url : item.path} onClick={() => this.getBreadCrumb(item.path, this.state.currentPath, item.key)}>{item.name}</Link>
                            </Breadcrumb.Item>)
                          })}
                        </> : ''
                    }
                  </Breadcrumb>
                </div>
                : this.state.currentPath && this.state.currentPath !== undefined ?
                  <div>
                    <Breadcrumb>
                      {this.state.currentPath.map(item => {
                        return (<Breadcrumb.Item key={item.key}>
                          <Link to={item.url ? item.url : item.path} onClick={() => this.getBreadCrumb(item.path, this.state.currentPath, item.key)}>{item.name}</Link>
                        </Breadcrumb.Item>)
                      })}
                    </Breadcrumb>
                  </div> : ''}
            </div>
          </div>
        </div>
        {this.state.showApp && (
          <section className="contentSecton">
            <div
              className={
                this.state.collapseNavBar
                  ? "mainNavSection collapseProduct"
                  : "mainNavSection"
              }
            >
              <div className="customSideMenu" id="sidemenu" >
                <ul style={{padding:'0px'}}>
                  {this.state.currentSelectMenu.length > 0 ? <>
                    {this.state.currentSelectMenu && this.state.currentSelectMenu.map((item) => {
                      if (item.submenu && item.submenu.length > 0) {
                        return (
                          <li key={item.key} className={(this.state.selectedSubMenu === item.key) ? 'active' : 'noActive'} style={{paddingTop:'0px'}}>
                            {/* <Link to={item.url ? item.url : item.urls} onClick={() => this.navigationRouter(item)} style={{display:'flex', justifyContent:'center',backgroundColor:'#fff',margin:'2px',padding:'15px'}}> <i className={item.icon ? item.icon : ""}></i> {item.name}</Link> */}
                            <ul style={{padding:'0px',marginTop:'0px'}}>
                              {item.submenu && item.submenu.map((subItem) => {
                                if (subItem.displayId === 1) {
                                  if (subItem.submenu.length > 0) {
                                    // if (subItem.submenu.length > 0 && subItem.url === null) {
                                    return (
                                      <>
                                        <li
                                          className={(this.state.selectedSubMenu === subItem.key) ? ' active' : ' noActive'}
                                          key={subItem.key}
                                          id={subItem.id}
                                          style={{paddingTop:'0px',paddingBottom:'0px'}}
                                        >
                                          {subItem.url !== null ?
                                            <Link
                                              to={subItem.url ? subItem.url : subItem.urls}
                                              onClick={() => this.navigationRouter(subItem)} className="col text-center"
                                            >
                                              <i className="fas fa-window-minimize"></i>
                                              {subItem.name}
                                            </Link>
                                            : subItem.name === "Masters" ?
                                              <Link
                                                to={window.location.pathname}
                                                style={{ cursor: "not-allowed" }}
                                                className="col text-center"
                                              >
                                                <i className="fas fa-window-minimize"></i>
                                                {subItem.name}
                                              </Link>
                                              : <Link
                                                className={(subItem.name === "Manage Inventory" ? ' col text-center' : 'col text-center')}
                                                to={subItem.url ? subItem.url : subItem.urls}
                                                style={{display:'none'}}
                                                
                                              >
                                                {}
                                                <i className="fas fa-window-minimize"></i>
                                                {subItem.name}
                                              </Link>
                                            // onClick={() => this.navigationRouter(subItem)}
                                          }
                                          {/* <ul className="mt-2 orgloclink"> */}
                                            {subItem.submenu && subItem.submenu.map((subItem1, i) => {
                                              return (
                                                <>
                                                  <li
                                                    className={`${(sessionStorage.getItem("keypath") === subItem1.key || (subItem1.submenu && subItem1.submenu.some((li=>li.key === sessionStorage.getItem("keypath")))) ) ? 'ourProducts active' : 'ourProducts noActive'}`}
                                                    key={i} style={{backgroundColor:this.state.selectedSubMenu === subItem1.key || (subItem1.submenu && subItem1.submenu.some((li=>li.key === sessionStorage.getItem("keypath"))))  ?'#104dba':'#fafafa',margin:'2px',}}
                                                    id={subItem1.id + i} 
                                                      // onClick={()=>this.setState({locationMenu:true})}
                                                      //  onMouseEnter={()=>this.setState({locationMenu:true})} 
                                                      //  onMouseLeave={()=>this.setState({locationMenu:false})} 
                                                  >
                                                     {}
                                                     {/* <Tooltip/>
                                                     <Modal className="locationMenu" open={this.state.locationMenu} footer={null}
                                                      onMouseEnter={(e)=>{
                                                        console.log('event',e.target,e.type)
                                                        // e.stopPropagation()
                                                        // e.preventDefault()
                                                        this.setState({locationMenu:true})}} 
                                                      onMouseLeave={(e)=>{
                                                        console.log('event Leave',e.target,'type',e.type)
                                                        // e.stopPropagation()
                                                        // e.preventDefault()
                                                        this.setState({locationMenu:false})} 
                                                      }
                                                     > */}
                                                     <Tooltip placement="left" color="#fff"
                                                     title= {subItem1.submenu && subItem1.submenu.map((subItem2) => {
                                                      return (
                                                        <>
                                                          <li
                                                            key={i}
                                                            id={subItem2.id + i}
                                                            className={(sessionStorage.getItem("keypath") === subItem2.key) ? 'active p-all-SD-10 ' : 'noActive'}
                                                            style={{backgroundColor:'fff',padding:'2px'}}
                                                          >
                                                            {}
                                                            <Link
                                                              title={subItem2.name}
                                                              to={subItem2.url ? subItem2.url : subItem2.urls}
                                                              onClick={() => this.navigationRouter(subItem2)}
                                                              style={{color:sessionStorage.getItem("keypath") === subItem2.key?'#104dba':'black'}}
                                                            >
                                                              <TfiLocationPin title={subItem2.name} color={sessionStorage.getItem("keypath") === subItem2.key?'#104dba':'black'} style={{ marginRight: '5px' }} />
                                                              {subItem2.name.length > 20 ? subItem2.name.substring(0, 20) + '...' : subItem2.name}
                                                            </Link>
                                                          </li>
                                                        </>
                                                      )
                                                    })
                                                    }>
                                                      {
                                                      subItem1.name === "Location Stock Levels" ?
                                                    //   <li
                                                    //   className={(this.state.selectedSubMenu === subItem1.key) ? 'ourProducts active' : 'ourProducts noActive'}
                                                    //   key={subItem.key}
                                                    //   style={{backgroundColor:this.state.selectedSubMenu === subItem1.key ?'blue':'#fafafa',margin:'2px',padding:'0px'}}
                                                    //   onClick={()=>this.setState({locationMenu:true})}
                                                    //    onMouseOut={()=>this.setState({locationMenu:false})} 
                                                    // >
                                                    // <div onMouseEnter={(e)=>{
                                                    //   console.log('event',e.target)
                                                    //   // e.stopPropagation()
                                                    //   // e.preventDefault()
                                                    //   this.setState({locationMenu:true})}} 
                                                    // onMouseLeave={(e)=>{
                                                    //   console.log('event Leave',e.target)
                                                    //   // e.stopPropagation()
                                                    //   // e.preventDefault()
                                                    //   this.setState({locationMenu:false})} 
                                                    // }
                                                    // >
                                                        <Link style={{display:'flex',flexDirection:'column',padding:'0px',justifyContent:'center',alignItems:'center',textAlign:'center' ,color:this.state.selectedSubMenu === subItem1.key || (subItem1.submenu && subItem1.submenu.some((li=>li.key === sessionStorage.getItem("keypath")))) ?'#fff':''}}
                                                        onClick={()=>this.setState({locationMenu:true})}
                                                        // //  onMouseOut={()=>this.setState({locationMenu:false})} 
                                                        // onMouseEnter={(e)=>{
                                                        //   console.log('event',e.target,e.type)
                                                        //   // e.stopPropagation()
                                                        //   // e.preventDefault()
                                                        //   this.setState({locationMenu:true})}} 
                                                        // onMouseLeave={(e)=>{
                                                        //   console.log('event Leave',e.target,'type',e.type)
                                                        //   // e.stopPropagation()
                                                        //   // e.preventDefault()
                                                        //   this.setState({locationMenu:false})} 
                                                        // }
                                                         >
                                                        {}
                                                        {/* <div onClick={()=>this.setState({locationMenu:true})}
                                                    onAbort={()=>this.setState({locationMenu:false})} */}
                                                    {/* > */}
                                                          {/* <i className="fas fa-window-minimize" ></i> */}
                                                          <img src={this.state.selectedSubMenu === subItem1.key || (subItem1.submenu && subItem1.submenu.some((li=>li.key === sessionStorage.getItem("keypath")))) ?`/Icons/Sub Navigation/${subItem1.name}/1.png`:`/Icons/Sub Navigation/${subItem1.name}/2.png`} alt='' style={{width:'25px',height:'25px'}}/>
                                                          <span className="mt-2 pl-1 pb-1">{subItem1.name}</span>
                                                          {/* </div> */}
                                                        </Link>
                                                        :
                                                      //   <li
                                                      //   className={(this.state.selectedSubMenu === subItem.key) ? 'ourProducts active' : 'ourProducts noActive'}
                                                      //   key={subItem.key}
                                                      //   style={{backgroundColor:this.state.selectedSubMenu === subItem1.key ?'blue':'#fafafa',margin:'2px',padding:'0px'}}
                                                      // >
                                                        <Link
                                                        style={{display:'flex',flexDirection:'column',padding:'0px',justifyContent:'center',alignItems:'center',textAlign:'center' ,color:this.state.selectedSubMenu === subItem1.key?'#fff':''}}
                                                          to={subItem1.url ? subItem1.url : subItem1.urls}
                                                          onClick={() => this.navigationRouter(subItem1)}
                                                        >
                                                           {}
                                                          {/* <TfiLocationPin style={{marginRight:'5px'}}/>  */}
                                                          <img src={this.state.selectedSubMenu === subItem1.key ?`/Icons/Sub Navigation/${subItem1.name}/1.png`:`/Icons/Sub Navigation/${subItem1.name}/2.png`} alt='' style={{width:'25px',height:'25px'}}/>
                                                          {/* <i className="fas fa-window-minimize" ></i> */}
                                                          <span className="mt-2">{subItem1.name}</span>
                                                        </Link>
                                                        // </li>
                                                    }
                                                    </Tooltip>
                                                    
                                                  </li>
                                                  
                                                    
                                                  {/* <div style={{height:'100px',width:'100px',display:'#fff'}}>

                                                  </div> */}
                                                  {/* <>
                                                    {subItem1.submenu && subItem1.submenu.map((subItem2) => {
                                                      return (
                                                        <>
                                                          <li
                                                            key={i}
                                                            id={subItem2.id + i}
                                                            className={(sessionStorage.getItem("keypath") === subItem2.key) ? 'active p-all-SD-10 ' : 'noActive'}
                                                          >
                                                            <Link
                                                              title={subItem2.name}
                                                              to={subItem2.url ? subItem2.url : subItem2.urls}
                                                              onClick={() => this.navigationRouter(subItem2)}
                                                            >
                                                              <TfiLocationPin title={subItem2.name} style={{ marginRight: '5px' }} />
                                                              {subItem2.name.length > 20 ? subItem2.name.substring(0, 20) + '...' : subItem2.name}
                                                            </Link>
                                                          </li>
                                                        </>
                                                      )
                                                    })
                                                    }
                                                  </> */}
                                                  {/* </li> */}
                                                </>
                                              )
                                            })
                                            }
                                          {/* </ul> */}
                                        </li>
                                      </>
                                    )
                                  } 
                                  else {
                                    return (
                                      <li
                                        className={(this.state.selectedSubMenu === subItem.key) ? 'ourProducts active' : 'ourProducts noActive'}
                                        key={subItem.key}
                                        style={{backgroundColor:this.state.selectedSubMenu === subItem.key ?'#104dba':'#fafafa',margin:'2px',}}
                                      >
                                        <Link
                                         className="col text-center" style={{display:'flex',flexDirection:'column',padding:'0px',justifyContent:'center',alignItems:'center',color:this.state.selectedSubMenu === subItem.key?'#fff':''}}
                                          to={subItem.url ? subItem.url : subItem.urls}
                                          onClick={() => this.navigationRouter(subItem)}>
                                            <img src={this.state.selectedSubMenu === subItem.key ?`/Icons/Sub Navigation/${subItem.name}/1.png`:`/Icons/Sub Navigation/${subItem.name}/2.png`} alt='' style={{width:'25px',height:'25px'}}/>
                                          {/* <i className="fas fa-window-minimize"></i> */}
                                          <span className="mt-2">{subItem.name}</span>
                                        </Link>
                                      </li>
                                    )
                                  }
                                }
                              })}
                            </ul>
                          </li>
                        )
                      }
                    })
                    }
                  </> : ''}
                </ul>
              </div>
            </div>
            <div className="mainContent">
              <div className="col-md-12 pt-1 px-0">
                <div className="contentSection">
                  <div className="col-md-12">
                    <Route exact path='/layout' key="layout" >
                      <DashBoardTab layState={this.state !== undefined ? this.state : {}} />
                    </Route>
                    {this.state.findAccessMenu && this.state.findAccessMenu.map(route => {
                      return (<>
                        {(route.path && this.state.accessPermission === "5000")
                          || (route.path && this.state.accessPermission === "5001")
                          || (route.path && this.state.accessPermission === "5002")
                          || (route.path && this.state.accessPermission === "5003")
                          ? <Route path={route.path} component={route.component}  key={route.key} />
                          : <Route path="*" element={<PagenotFound />} />
                        }
                      </>)
                    })}
                  </div>
                </div>
              </div>
              <div style={{ position: 'fixed', right: '15px', bottom: '15px', display: this.state.helpButton && window.location.pathname !== "/layout/Home" ? '' : 'none' }}>
                <MdOutlineHelp color="#ef9d43" size={40} onClick={() => this.setState({ openHelpModal: true, dashboardHelpShow: false })} style={{ cursor: 'pointer' }} />
              </div>
            </div>
          </section>
        )}
        {this.props.loader_reducer.loader ? (
          <div className="spinLoader">
            <Spin size="large" />
          </div>
        ) : (
          ""
        )}
        <div >
          <Drawer
            id="manageMenu"
            className="productDrawer"
            placement="top"
            closable={false}
            onClose={this.dismissPanel}
            open={this.state.isOpen}
            style={{ width: "100px!important", }}
          >
            <div className="ourProducts">
              {this.state.navbarlist.map(item =>
                <div
                  className={this.state.currentSelectMenu.length > 0 && this.state.currentSelectMenu[0].name === item.name ? 'active' : 'noActive'}
                  onClick={() => this.currentMenuHandler(item, true)}
                  style={{display:'flex',flexDirection:'column' , alignItems:'center',}}
                >
                  {/* <i className={item.icon ? item.icon : ""}></i> */}
                  <img src = {this.state.currentSelectMenu.length > 0 && this.state.currentSelectMenu[0].name === item.name ?`/Icons/Main Navigation/${item.name}/1.png`:`/Icons/Main Navigation/${item.name}/2.png`} alt='' style={{width:'25px',height:'25px'}}/>
                  <span className="mt-2" >{item.name}</span>
                </div>
              )}
            </div>
          </Drawer>
        </div>
        <Drawer
          title="Themes"
          placement="right"
          onClose={this.dismissThemePanel}
          open={this.state.isThemeOpen}
        >
          <Radio.Group onChange={this._onChange} id="isThemeOpen" value={sTheme}>
            {options.map((item) => {
              return (
                <Radio key={item.key} value={item.text} disabled>
                  {item.displayValue}
                </Radio>
              );
            })}
          </Radio.Group>
          <label htmlFor="selectFont" className="chooseColor">
            Selected Font
          </label>
          <Select id="selectFont"
            style={{ width: "100%" }}
            defaultValue={sFont}
            value={FontsList.key} disabled
            onChange={(event, value) => this.handleDropDownChange(event, value)}
          >
            {FontsList.Option.map((item) => {
              return (
                <Option key={item.key} value={item.text}>
                  {item.text}
                </Option>
              );
            })}
          </Select>
          <div className="SwatchColorPickerSection">
            <label htmlFor="chooseColor" className="chooseColor">
              Choose Color
            </label>
            <CirclePicker colors={ThemesCells} circleSize={30} onChange={this.handleChangeComplete} disabled={true} />
            <div style={styles.swatch} onClick={this.handleClick}>
              <div style={styles.color} />
            </div>
            {this.state.displayColorPicker ? <div style={styles.popover}>
              <div style={styles.cover} onClick={this.handleClose} />
              <SketchPicker color={this.state.color} onChange={this.handleChangeComplete} disabled />
            </div> : null}
          </div>
        </Drawer>
        {error ? (
          <AdqDialogBox
            dialogBox={dialogBoxData}
            dialogContentProps={dialogContent}
            selectedDialogBoxValue={this.onDialogboxHandle}
          />
        ) : ("")}
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ loader_reducer, breadCrumb_reducer, orglogo_reducer, orgBank_reducer }) => {
  return { loader_reducer, breadCrumb_reducer, orglogo_reducer, orgBank_reducer };
};

const mapDispatchToProps = {
  saveBreadCrumbAsync: saveBreadCrumb,
  updateManageInventoryItem: updateManageItem,
  updateLocatioApiCall: locationApiCall,
  notificationApiCall: notificationApi,
  OrgLogoApiCall: orgLogoApi_request,
  OrgBankApiCall: orgBankApi_request,
  genConfigApiCall: generalConfigApi_request,
  termsCondListApiCall: getListofTerms_request,
  ledgTransListApiCall: getLedgTransList_request,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LayoutPage));

export const notificationBtnObj = {
  name: "dismissall",
  ctrltype: "btn",
  labels: "",
  type: "default",
  text: "Dismiss All",
  icon: 'addIcon',
  disabled: false,
  id: "n_dismiss_Btn"
}