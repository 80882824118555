export const supplyConfig = [
    {
        ctrltype: "switch",
        name: 'enablebarcoding',
        label: 'Enable Barcoding',
        value: false,
    },
    {
        ctrltype: "switch",
        name: 'enablebatches',
        label: 'Enable Batches',
        value: false,
    },
    {
        ctrltype: "switch",
        name: 'enableserialization',
        label: 'Enable Serialization',
        value: false,
    },
    {
        ctrltype: "switch",
        name: 'enableshelflife',
        label: 'Enable Shelf Life',
        value: false,
    },
    {
        ctrltype: "switch",
        name: 'enablevariants',
        label: 'Enable Variants Based SKU',
        value: false,
    },
    {
        ctrltype: "switch",
        name: 'ecommerce',
        label: 'E-Commerce (Listany)',
        value: false,
    }
]

export const inventoryConfig = [
    {
        ctrltype: "switch",
        name: 'inventoryTracking',
        label: 'Track Inventory',
        value: false,
        disabled: false,
    },
    {
        ctrltype: "switch",
        name: 'isAllowNegative',
        label: 'Allow Negative Quantity',
        value: false,
    }
]
export const tCSSettingConfig = [
    {
        ctrltype: "switch",
        name: 'isTDSApplicable',
        label: 'TDS Applicable',
        value: false,
        disabled: false,
    },
    {
        ctrltype: "switch",
        name: 'isTCSApplicable',
        label: 'TCS Applicable',
        value: false,
    }
]

export const invoicingSub = [
    {
        ctrltype: "switch",
        name: 'isRoundOffTotals',
        label: 'Round off in Transactions',
        value: false,
    },
    // {
    //     ctrltype: "switch",
    //     name: 'tdsadjustments',
    //     label: 'Tds Adjustments',
    //     value: false,
    // },
    {
        ctrltype: "switch",
        name: 'otherAdjustments',
        label: 'Other Adjustments',
        value: false,
    }
]

export const genConfigSub = [
    {
        typectrl: "drp",
        ctrltype: "dropdown",
        placeHolder: "₹ ",
        isRequired: true,
        errormsg: false,
        disabled: false,
        label: "Define Currency:",
        name: "currencyCode",
        key: 1,
        tooltip: 'Please Select the Currency Code',
        isTooltip: true,
        Option: [
            { key: 1, text: "₹" },
            { key: 2, text: "$" },
        ],
    },
    {
        typectrl: "drp",
        ctrltype: "dropdown",
        placeHolder: "DD-MM-YYYY ",
        isRequired: true,
        errormsg: false,
        disabled: false,
        label: "Date Format:",
        name: "dateFormat",
        tooltip: 'Please Select the Date Format',
        isTooltip: true,
        key: 2,
        Option: [
            { key: 2, text: "DD-MM-YYYY" },
            // { key: 1, text: "MM-DD-YYYY" },
        ],
    },
]

export const accountingSub = [
    {
        ctrltype: "switch",
        name: 'lockprev',
        label: 'Lock Previous Year',
        value: false,
    },
    {
        typectrl: "drp",
        ctrltype: "dropdown",
        placeHolder: "select ",
        isRequired: true,
        errormsg: false,
        label: "Date:",
        name: "prevfinancialdate",
        tooltip: 'Please Select the Date Range',
        isTooltip: true,
        key: '',
        Option: [
            { key: 1, text: "2022-2023", start: "01-04-2022", end: "31-03-2023" },
            { key: 2, text: "2021-2022", start: "01-04-2021", end: "31-03-2022" },
            { key: 3, text: "2020-2021", start: "01-04-2020", end: "31-03-2021" },
            { key: 4, text: "2019-2020", start: "01-04-2019", end: "31-03-2020" },
            { key: 5, text: "2018-2019", start: "01-04-2018", end: "31-03-2019" },
        ],
    },

]

export const configMenus = [
    {
        key: 'sub1',
        name: 'General Configurations',
        menuitems: [
            {
                key: "Config General",
                name: 'General'
            },
            {
                key: "1",
                name: 'Number Series'
            },
            {
                key: "2",
                name: 'Additional Config'
            },
            {
                key: "termsMaster",
                name: 'Terms & Conditions'
            },
            {
                key: "businessType",
                name: 'Business Type'
            },

        ]
    },

    {
        key: 'sub2',
        name: 'Masters',
        menuitems: [
            {
                key: "Config Masters",
                name: 'Item Master'
            },
            {
                key: "bankDetails",
                name: 'Bank Details'
            },
        ]
    },
    {
        key: 'sub9',
        name: 'Transaction Settings',
        menuitems: [
            {
                key: "Settings",
                name: 'Transaction Settings'
            },
        ]
    },
    {
        key: 'sub3',
        name: 'Invoicing',
        menuitems: [
            {
                key: "Config Invoicing",
                name: 'Adjustments'
            },
            {
                key: "5",
                name: 'Invoice Builder'
            }
        ]
    },
    {
        key: 'sub4',
        name: 'Inventory',
        menuitems: [
            {
                key: "Config Inventory",
                name: 'Inventory'
            },
        ]
    },
    // {
    //     key: 'sub5',
    //     name: 'Themes',
    //     menuitems: [
    //         {
    //             key: "Config Themes",
    //             name: 'Themes'
    //         }
    //     ]
    // },
    // {
    //     key: 'sub6',
    //     name: 'Workflow Management',
    //     menuitems: [
    //         {
    //             key: "Config Workflow Management",
    //             name: 'Workflow Management'
    //         }
    //     ]
    // },
    {
        key: 'sub7',
        name: 'E-Invoice ',
        menuitems: [
            {
                key: "Config E-Invoice",
                name: 'IRN Credentials'
            }
        ]
    },
    {
        key: 'sub8',
        name: 'Upload Logo ',
        menuitems: [
            {
                key: "org Logo",
                name: 'Upload Logo'
            }
        ]
    },
    // {
    //     key: 'sub199',
    //     name: 'Logistic Partner',
    //     menuitems: [
    //         {
    //             key: "Logistic Partner",
    //             name: 'Logistic Partner'
    //         }
    //     ]
    // },
    {
        key: 'sub99',
        name: 'TDS/TCS Setting',
        menuitems: [
            {
                key: "TDS/TCS Setting",
                name: 'TDS/TCS Setting'
            }
        ]
    },
    {
        key: 'sub44',
        name: 'Custom Theme',
        menuitems: [
            {
                key: "Custom Theme",
                name: 'Custom Theme'
            }
        ]
    }

]

export const actionbtns = [
    {
        ctrltype: "btn",
        labels: "",
        type: "default",
        name: "draft",
        hidden: false,
        text: "Save as Draft",
        isDisabled: false,
        id: 'oms_draft_btn',
    },
    {
        ctrltype: "btn",
        labels: "",
        type: "primary",
        name: "update",
        hidden: true,
        text: "Update",
        isDisabled: false,
        id: 'oms_draft_btn',
    },
    {
        ctrltype: "btn",
        labels: "",
        type: "primary",
        hidden: false,
        name: "publish",
        text: "Publish",
        isDisabled: false,
        id: 'oms_publish_btn',
    },
    {
        ctrltype: "btn",
        labels: "",
        type: "default",
        hidden: false,
        name: "cancel",
        text: "Cancel",
        isDisabled: false,
        id: 'oms_cancel_btn',
    },
];

export const statusbtns = [
    // {
    //     ctrltype: "btn",
    //     labels: "",
    //     type: "default",
    //     hidden: false,
    //     name: "default",
    //     text: "Default",
    //     isDisabled: false,
    //     id: 'oms_setdefault_btn',
    // },
    {
        ctrltype: "btn",
        labels: "",
        type: "primary",
        hidden: false,
        name: "newstatus",
        text: "New Workflow",
        isDisabled: false,
        id: 'oms_newstatus_btn',
    },
]

export const defaultOrderStatus = ["New", "Hold", "Rejected", "Accepted", "Packed", "Ready to Ship",
    "Intransit", "Out of Delivery", "Delivered", "Cancelled"];

export const actionValues = [
    { id: 1, key: 1, text: "Hold Inventory" },
    { id: 2, key: 2, text: "Allocate Inventory" },
    { id: 3, key: 3, text: "Release Inventory" },
    { id: 4, key: 4, text: "Generate Invoice" },
    { id: 5, key: 5, text: "Logistic Details" }
];

export const defaultActionsValidStates = [
    { selectActionKey: 1, selectAction: 'Hold Inventory', validStateValue: ["Accepted", "Hold", "Rejected", "Cancelled"], validStateKeys: [4, 2, 3, 10] },
    { selectActionKey: 0, selectAction: '', validStateValue: ["Accepted", "Rejected", "Cancelled"], validStateKeys: [4, 3, 10] },
    { selectActionKey: 3, selectAction: 'Release Inventory', validStateValue: [], validStateKeys: [] },
    { selectActionKey: 2, selectAction: 'Allocate Inventory', validStateValue: ["Packed", "Rejected"], validStateKeys: [5, 3] },
    { selectActionKey: 5, selectAction: 'Logistic Details', validStateValue: ['Ready to Ship', 'Accepted'], validStateKeys: [6, 4] },
    { selectActionKey: 4, selectAction: 'Generate Invoice', validStateValue: ['Intransit'], validStateKeys: [7] },
    { selectActionKey: 0, selectAction: '', validStateValue: ["Out of Delivery"], validStateKeys: [8] },
    { selectActionKey: 0, selectAction: '', validStateValue: ['Delivered'], validStateKeys: [9] },
    { selectActionKey: 0, selectAction: '', validStateValue: [], validStateKeys: [] },
    { selectActionKey: 3, selectAction: 'Release Inventory', validStateValue: [], validStateKeys: [] },
]


export const genConfigSaveObj = {
    "id": 0,
    "createdBy": localStorage.getItem('UserName') || 'John Wick',
    "createdDate": new Date(),
    "modifiedDate": new Date(),
    "modifiedBy": 'Ubooks',
    "orgCode": localStorage.getItem('OrgCode'),
    "isActive": true,
    "currencyCode": "1",
    "dateFormat": "2",
    "autoRoundOffTotals": false,
    "tdsadjustments": false,
    "otherAdjustments": false,
    "enableBarCode": false,
    "isAllowNegative": false,
    "enableBatch": false,
    "enableSerialization": false,
    "enableShelfLife": false,
    "enableVariantsBasedSku": false,
    "ecommerce": false,
    "trackInventory": false,
    "isMultiItemsInvoice": true,
    "activeTemplateId": 2, // default invoice template
}

export const businessType = [
    {
        typectrl: "txt",
        ctrltype: "text",
        termsHeight: "termScreen",
        label: "Business Type:",
        name: "businessType",
        isSpecialChar: false,
        value: '',
        type: "textarea",
        placeholder: "",
        isRequired: true,
        multiline: true,
        maxLength: 200,
        errormsg: false,
        noLabel : true
    },
    {
        typectrl: "txt",
        ctrltype: "btn",
        labels: "Update",
        type: "primary",
        text: "Update",
        disabled: false,
        id: "updateBtn",
    }
]