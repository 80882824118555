import Oidc from 'oidc-client';

export function log() {
  Array.prototype.forEach.call(arguments, function (msg) {
    if (msg instanceof Error) {
      msg = "Error: " + msg.message;
    } else if (typeof msg !== 'string') {
      msg = JSON.stringify(msg, null, 2);
    }
  });
}

//IDP Production
var config_prod = {
  authority: "https://ubooks360.com:93/" , //"https://localhost:44308/" ,//"https://qaidpserver.azurewebsites.net/",//process.env.REACT_APP_MODE === 'production' ? process.env.REACT_APP_PRODUCTION_AUTHORITY : process.env.REACT_APP_lOCAL_AUTHORITY,
  client_secret : "Secret",//"fjKnKbEibtEnDygqjGMFTQmya8nsU+ppdxzjgVjfreg=",
  client_id: "uBooksweb2",//process.env.REACT_APP_MODE === 'production' ? process.env.REACT_APP_PRODUCTION_CLIENTID : process.env.REACT_APP_lOCAL_CLIENTID,
  redirect_uri:"https://ubooks360.com/layout", //process.env.REACT_APP_MODE === 'production' ? process.env.REACT_APP_PRODUCTION_REDIRECTURL : process.env.REACT_APP_lOCAL_REDIRECTURL,
  response_type: "code",//"id_token token",
  scope:"openid profile api1 offline_access",
  post_logout_redirect_uri :"https://ubooks360.com:443/identity/login",
  savetokens :"true",
  loadUserInfo: "true",
  filterProtocolClaims: "true",
  tokenendpoint :"https://ubooks360.com:93/connect/token",
  authorizeendpoint :"https://ubooks360.com:93/connect/authorize",
  granttype :"authorization_code",
  cookiepath :"/",
  usepkce :"true",
  UpdateAccessTokenClaimsOnRefresh :"true",
  AllowOfflineAccess : "true"
};


// QA new
var config_qa = {
  authority: "http://20.244.41.38:93/" , //"https://localhost:44308/" ,//"https://qaidpserver.azurewebsites.net/",//process.env.REACT_APP_MODE === 'production' ? process.env.REACT_APP_PRODUCTION_AUTHORITY : process.env.REACT_APP_lOCAL_AUTHORITY,
  client_secret : "Secret",//"fjKnKbEibtEnDygqjGMFTQmya8nsU+ppdxzjgVjfreg=",
  client_id: "uBooksweb2",//process.env.REACT_APP_MODE === 'production' ? process.env.REACT_APP_PRODUCTION_CLIENTID : process.env.REACT_APP_lOCAL_CLIENTID,
  redirect_uri:"http://20.244.41.38:1025/layout", //process.env.REACT_APP_MODE === 'production' ? process.env.REACT_APP_PRODUCTION_REDIRECTURL : process.env.REACT_APP_lOCAL_REDIRECTURL,
  response_type: "code",//"id_token token",
  scope:"openid profile api1 offline_access",
  post_logout_redirect_uri :"http://20.244.41.38:1025/identity/login",
  savetokens :"true",
  loadUserInfo: "true",
  filterProtocolClaims: "true",
  tokenendpoint :"http://20.244.41.38:93/connect/token",
  authorizeendpoint :"http://20.244.41.38:93/connect/authorize",
  granttype :"authorization_code",
  cookiepath :"/",
  usepkce :"true",
  UpdateAccessTokenClaimsOnRefresh :"true",
  AllowOfflineAccess : "true"
};

  
// Dev server
var config_dev = {
  authority: "http://192.168.2.121:93/" , //"https://localhost:44308/" ,//"https://qaidpserver.azurewebsites.net/",//process.env.REACT_APP_MODE === 'production' ? process.env.REACT_APP_PRODUCTION_AUTHORITY : process.env.REACT_APP_lOCAL_AUTHORITY,
  client_secret : "Secret",//"fjKnKbEibtEnDygqjGMFTQmya8nsU+ppdxzjgVjfreg=",
  client_id: "uBooksweb2",//process.env.REACT_APP_MODE === 'production' ? process.env.REACT_APP_PRODUCTION_CLIENTID : process.env.REACT_APP_lOCAL_CLIENTID,
  redirect_uri:"http://192.168.2.121:1026/layout", //process.env.REACT_APP_MODE === 'production' ? process.env.REACT_APP_PRODUCTION_REDIRECTURL : process.env.REACT_APP_lOCAL_REDIRECTURL,
  response_type: "code",//"id_token token",
  scope:"openid profile api1 offline_access",
  post_logout_redirect_uri :"http://192.168.2.121:1026/",
  savetokens :"true",
  loadUserInfo: "true",
  filterProtocolClaims: "true",
  tokenendpoint :"http://192.168.2.121:93/connect/token",
  authorizeendpoint :"http://192.168.2.121:93/connect/authorize",
  granttype :"authorization_code",
  cookiepath :"/",
  usepkce :"true",
  UpdateAccessTokenClaimsOnRefresh :"true",
  AllowOfflineAccess : "true"
};


// Local
  var config_local = {
    authority: "http://192.168.2.121:93/", //"https://localhost:44308/" ,//"https://qaidpserver.azurewebsites.net/",//process.env.REACT_APP_MODE === 'production' ? process.env.REACT_APP_PRODUCTION_AUTHORITY : process.env.REACT_APP_lOCAL_AUTHORITY,
    client_secret: "secret",//"fjKnKbEibtEnDygqjGMFTQmya8nsU+ppdxzjgVjfreg=",
    client_id: "uBooksweb",//process.env.REACT_APP_MODE === 'production' ? process.env.REACT_APP_PR
    ODUCTION_CLIENTID : process.env.REACT_APP_lOCAL_CLIENTID,
    redirect_uri: "http://localhost:3000/layout", //process.env.REACT_APP_MODE === 'production' ? process.env.REACT_APP_PRODUCTION_REDIRECTURL : process.env.REACT_APP_lOCAL_REDIRECTURL,
    response_type: "code",//"id_token token",
    scope: "openid profile api1 offline_access",
    post_logout_redirect_uri: "http://localhost:3000/",
    savetokens: "true",
    loadUserInfo: "true",
    filterProtocolClaims: "true",
    tokenendpoint: "http://192.168.2.121:93/connect/token", 
    authorizeendpoint: "http://192.168.2.121:93/connect/authorize",
    granttype: "authorization_code",
    cookiepath: "/",
    usepkce: "true",
    UpdateAccessTokenClaimsOnRefresh: "true",
    AllowOfflineAccess: "true",
  };

let dataConfig = process.env.NODE_ENV === 'development' ? config_local 
  : process.env.REACT_APP_RUN === 'dev' ? config_dev
  : process.env.REACT_APP_RUN === 'qa' ? config_qa
  : process.env.REACT_APP_RUN === 'prod' ? config_prod
        : config_local 
        
const mgr = new Oidc.UserManager(dataConfig);

mgr.getUser().then(function (user) {
  if (user) { 
    log("User logged in", user.profile);
  } else {
    log("User not logged in");
  }
});

mgr.processSigninResponse().then(function (response) {
  log("signin response success", response);
  var userDetails = response.profile;
  var ClientName = '';
  if (typeof userDetails.UserName === "string") {
    ClientName = userDetails.UserName;
  } else {
    ClientName = userDetails.UserName[0];
  }
  localStorage.setItem('userDetails', JSON.stringify(userDetails));
  localStorage.setItem('UserName', ClientName);
  sessionStorage.setItem('oidc.user:', JSON.stringify(response));
  removeAccessTokenFromUrl();
  window.history.pushState('new', 'title', 'layout')
  window.location.pathname = '/layout'
  window.location.reload();
}).catch(function (err) {
});

export function login() {
  localStorage.clear();
  mgr.signinRedirect();
};

export function removeAccessTokenFromUrl() {
  const { history, location } = window
  const { search } = location
  if (search && search.indexOf('access_token') !== -1 && history && history.replaceState) {
    // remove access_token from url
    // eslint-disable-next-line
    const cleanSearch = search.replace(/(\&|\?)access_token([_A-Za-z0-9=\.%]+)/g, '').replace(/^&/, '?')
    // replace search params with clean params
    const cleanURL = location.toString().replace(search, cleanSearch)
    // use browser history API to clean the params
    history.replaceState({}, '', cleanURL);
  }
}

export function logout() {
  var value =sessionStorage.getItem('oidc.user:');
  if(value !== null){
  var value2= JSON.parse(value);
  mgr.signoutRedirect({id_token_hint :value2.id_token?value2.id_token:''});
    localStorage.clear();
  sessionStorage.clear();
  // sessionStorage.Abandon();
  }
}